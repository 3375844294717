import parseHtmlString, { DOMNode, domToReact } from "html-react-parser";
import { HtmlTag } from "../../../interfaces/variousInterfaces";
import { sanitizeHtml } from "../../general/sanitizeHtml";
import { replaceEmojis } from "../replaceEmojis";
import { addDataAttrToListElements } from "./helpers/addDataAttrToListElements";
import { nodeIsTag } from "./helpers/nodeIsTag";
import {
  ElementIdToComponentMap,
  TagToComponentMap,
} from "./replaceHtmlElementInterfaces";

/**
 * Parse an html string to react, replacing certain elements with custom function components.
 *
 * Note that this will also sanitize the given html.
 *
 * @param html the raw html string
 *
 * @param mapFromTagsToComponents a mapping from html tag names (div, p …) to components that will replace them.
 *
 * @param mapFromIdsToComponents a mapping from ids to factories that return function components from a domNode.
 * This takes precedence over the tag mapping.
 */
export const replaceHtmlWithFunctionComponent = (
  html: string | undefined,
  mapFromTagsToComponents?: TagToComponentMap,
  mapFromIdsToComponents?: ElementIdToComponentMap
): string | JSX.Element | JSX.Element[] => {
  const emojisParsed = replaceEmojis(html);
  const sanitizedHTML = sanitizeHtml(emojisParsed);

  /*
   * This replace function is used for the top-level call to the html-react-parser,
   *  and also for recursive calls to handle nested element structures.
   */
  function replaceDomNode(domNode: DOMNode) {
    if (!nodeIsTag(domNode)) {
      return null;
    }

    const id = domNode.attribs.id;
    const tagName = domNode.name as HtmlTag;

    addDataAttrToListElements(domNode);

    const ComponentFromId = mapFromIdsToComponents?.[id];

    /*
     * html-react-parser sets domNode.name as string,
     *  but we can be sure it's an HtmlTag.
     */
    const ComponentFromTag = mapFromTagsToComponents
      ? mapFromTagsToComponents[tagName]
      : undefined;

    const Component = ComponentFromId || ComponentFromTag;

    return Component ? (
      <Component replacedElement={domNode}>
        {domToReact(domNode.children, {
          replace: replaceDomNode,
        })}
      </Component>
    ) : null;
  }

  return parseHtmlString(sanitizedHTML, {
    replace: replaceDomNode,
  });
};
