import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import * as ItConsultingLottie from "../../../public/lottie-jsons/it-consulting.json";
import ItConsultingImage from "../../../public/media/services/consulting.jpg";
import { MainService } from "../../interfaces/MainService";
import { MainServiceData } from "../../interfaces/MainServiceData";
import { useTeamMemberReik } from "../team/teamMembers/reik";

export const useServiceItConsulting = (): MainServiceData => {
  const { t, i18n } = useTranslation<"service">("service");
  const germanT = i18n.getFixedT(Language.German, "service");
  const englishT = i18n.getFixedT(Language.English, "service");
  const reik = useTeamMemberReik();

  return useMemo(
    () => ({
      lottieAnimationJson: ItConsultingLottie,
      title: t("itConsulting.title"),
      text: t("itConsulting.text"),
      slug: t("itConsulting.slug"),
      slugs: {
        [Language.German]: germanT("itConsulting.slug"),
        [Language.English]: englishT("itConsulting.slug"),
      },
      contactPersonHeader: t("itConsulting.contactPerson.title"),
      contactPersonSubTitle: t("itConsulting.contactPerson.hint"),
      relatedProjectsHeader: t("itConsulting.relatedProjectsHeader"),
      subServiceHeader: t("itConsulting.subServiceHeader"),
      heroSlides: [{ image: ItConsultingImage }],
      contactTeamMember: reik,
      service: MainService.ItConsulting,
      metaInformation: {
        title: t("itConsulting.meta.title"),
        description: t("itConsulting.meta.description"),
      },
    }),
    [englishT, germanT, reik, t]
  );
};
