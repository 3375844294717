import { StaticImageData } from "next/image";
import { ReactElement } from "react";
import { TranslatableText } from "./TranslatableText";
import { MetaInformation } from "./metaInformation";

export enum Project {
  DeineInsel = "deine-insel",
  Resolvio = "resolvio",
  Medihead = "medihead",
  Bnbhotels = "b-and-b-hotels",
  Picwise = "picwise",
  Rheinelement = "rheinelement",
  Ksv = "ksv",
  EvolvingRegions = "evolving-regions",
  Vitis = "vitis",
  ConCon2021 = "concon-2021",
  BouldernPfalz = "bouldern-pfalz",
}

export interface ProjectData {
  name: Project;
  title: string;
  subtitle: string;
  description: string | ReactElement;
  previewImage: StaticImageData;
  logo?: StaticImageData | ReactElement;
  website: string;
  hashtags: string[];
  slug: string;
  slugs: TranslatableText;
  details: ProjectDetail;
  metaInformation: MetaInformation;
}

export interface ProjectDetail {
  heroImages: StaticImageData[];
  steps: ProjectStep[];
}

export interface ProjectStep {
  title: string;
  description: string;
  image: StaticImageData | ReactElement;
}
