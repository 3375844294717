import { ButtonBase } from "@mui/material";
import { rethrow } from "@nvon/baseline";
import { motion, MotionProps } from "framer-motion";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { Language } from "../../../../config/LanguageConfiguration";
import EnglishIcon from "../../../../public/media/britain-icon.png";
import GermanyIcon from "../../../../public/media/germany-icon.png";
import { useGetCurrentUrl } from "../../../hooks/urls/useGetCurrentUrl";
import { useI18nActiveLanguage } from "../../../hooks/useI18nActiveLanguage";
import { useRouterActiveLanguage } from "../../../hooks/useRouterActiveLanguage";
import { useNWONLanguageSwitcherStyles } from "./NWONLanguageSwitcherStyles";

const buttonMotionProps: MotionProps = {
  whileHover: { scale: 1.1 },
  whileTap: { scale: 0.95 },
};

const NWONLanguageSwitcher = (): JSX.Element => {
  const { classes } = useNWONLanguageSwitcherStyles();
  const router = useRouter();
  const getCurrentUrl = useGetCurrentUrl();
  const i18nActiveLanguage = useI18nActiveLanguage();
  const routerActiveLanguage = useRouterActiveLanguage();
  const { i18n } = useTranslation<"common">();

  useEffect(() => {
    if (routerActiveLanguage !== i18nActiveLanguage) {
      const newUrl = getCurrentUrl(i18nActiveLanguage);

      router
        .push(newUrl, newUrl, {
          locale: i18nActiveLanguage,
        })
        .catch(rethrow);
    }
  }, [getCurrentUrl, i18nActiveLanguage, router, routerActiveLanguage]);

  const changeLanguage = useCallback(
    (newLanguage: Language) => {
      i18n.changeLanguage(newLanguage).catch(rethrow);
    },
    [i18n]
  );

  return (
    <>
      <motion.span {...buttonMotionProps}>
        <ButtonBase
          onClick={() => changeLanguage(Language.German)}
          disableRipple={true}
          className={classes.languageButton}
        >
          <Image
            src={GermanyIcon}
            alt="DE"
            className={
              i18nActiveLanguage === Language.German
                ? ""
                : classes.languageImageNonActive
            }
          />
        </ButtonBase>
      </motion.span>

      <motion.span {...buttonMotionProps}>
        <ButtonBase
          onClick={() => changeLanguage(Language.English)}
          disableRipple={true}
          className={classes.languageButton}
        >
          <Image
            src={EnglishIcon}
            alt="EN"
            className={
              i18nActiveLanguage === Language.English
                ? ""
                : classes.languageImageNonActive
            }
          />
        </ButtonBase>
      </motion.span>
    </>
  );
};

export default NWONLanguageSwitcher;
