import { AppBar, Container, Grid, useScrollTrigger } from "@mui/material";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import NWONLogo from "../../../../public/media/svg/nwon_logo_dark.svg";
import NWONLogoLight from "../../../../public/media/svg/nwon_logo_light.svg";
import { scrollTriggerConstant } from "../../../helpers/clientSide/constants";
import { useGetUrlParameters } from "../../../hooks/urls/useGetUrlParameters";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { useRootUrl } from "../../../hooks/urls/useRootUrl";
import { useInitializedMediaQuery } from "../../../hooks/useInitializedMediaQuery";
import theme, { mobileMenuBreakpoint } from "../../../layouts/theme";
import Link from "../../01-atoms/Link";
import NWONHeaderDesktop from "./NWONHeaderDesktop/NWONHeaderDesktop";
import NWONHeaderMobile from "./NWONHeaderMobile/NWONHeaderMobile";
import { useNWONHeaderStyles } from "./NWONHeaderStyles";

const NWONHeader = (): JSX.Element => {
  const { isMatching: isMobile } = useInitializedMediaQuery(
    theme.breakpoints.down(mobileMenuBreakpoint)
  );

  const isPageScrolled = useScrollTrigger(scrollTriggerConstant(isMobile));
  const { classes } = useNWONHeaderStyles();
  const url = useRootUrl();
  const hasLeadUrlParameter = useHasUrlParameter(UrlParameter.Lead);
  const parameters = useGetUrlParameters([
    UrlParameter.Lead,
    UrlParameter.CtaStyle,
  ]);

  return (
    <AppBar
      className={classes.appBar}
      elevation={isPageScrolled ? 20 : 0}
      classes={{ root: classes.appBarRoot }}
    >
      <Container className={classes.wrapper}>
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link href={`${url}${parameters}`} className={classes.logoLink}>
            {isPageScrolled ? (
              <NWONLogo className={classes.logoImage} />
            ) : (
              <NWONLogoLight className={classes.logoImage} />
            )}
          </Link>

          {isMobile && hasLeadUrlParameter === false ? (
            <NWONHeaderMobile />
          ) : (
            <NWONHeaderDesktop />
          )}
        </Grid>
      </Container>
    </AppBar>
  );
};

export default NWONHeader;
