import { Grid, Typography } from "@mui/material";
import { NWONFooterColumnProps } from "./NWONFooterColumnInterfaces";
import { useNWONFooterColumnStyles } from "./NWONFooterColumnStyles";

export const NWONFooterColumn = ({
  title,
  subTitleElement,
  gridWrapperClassName,
}: NWONFooterColumnProps): JSX.Element => {
  const { classes, cx } = useNWONFooterColumnStyles();

  return (
    <Grid item={true} className={cx(classes.footerItem, gridWrapperClassName)}>
      <Typography variant="h6" color="secondary" component="h3">
        {title}
      </Typography>

      {subTitleElement}
    </Grid>
  );
};
