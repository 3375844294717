import { Element as HtmlElement } from "html-react-parser";
import { nodeIsHeadline } from "./nodeIsHeadline";
import { nodeIsTag } from "./nodeIsTag";

export const addDataAttrToListElements = (domNode: HtmlElement): void => {
  const { children, parent } = domNode;
  if (!parent || !nodeIsTag(parent) || !(parent.name !== "li")) {
    return;
  }

  /*
   * While we set the data attributes on the lists, this is not where we need them:
   * - for list items that contain a headline as a child element, we want the headline to have the data attributes
   * - for "normal" list items, we want the `li` element to have the data attributes
   */

  const headlineChildElement = children.find(nodeIsHeadline);

  if (headlineChildElement) {
    addAttributes(headlineChildElement, parent);
  } else {
    addAttributes(domNode, parent);
  }
};

/**
 * Add attributes from the source to the target.
 */
const addAttributes = (
  targetElement: HtmlElement,
  sourceElement: HtmlElement
): void => {
  targetElement.attribs = adjustAttributes({
    ...targetElement.attribs,
    ...sourceElement.attribs,
  });
};

/**
 * Currently used as a way to insert spaces before "§" signs.
 *
 * The problem is that dompurify removes them from the original html.
 *
 * 🚨 Adjustment is performed in place.
 */
const adjustAttributes = (
  attributes: HtmlElement["attribs"]
): HtmlElement["attribs"] => {
  if (attributes["data-list-style-prefix"] === "§") {
    attributes["data-list-style-prefix"] = "§ ";
  }
  return attributes;
};
