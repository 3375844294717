import { Box, Container } from "@mui/material";
import { omit } from "lodash-es";
import { NWONSectionProps } from "./NWONSectionInterfaces";
import { useNWONSectionStyles } from "./NWONSectionStyles";

const NWONSection = ({
  children,
  className,
  containerProps,
  useBackgroundColorPrimaryMain,
  additionalPadding,
  id,
}: NWONSectionProps): JSX.Element => {
  const { classes, cx } = useNWONSectionStyles({
    useBackgroundColorPrimaryMain: !!useBackgroundColorPrimaryMain,
    additionalPadding: !!additionalPadding,
  });

  return (
    <Box component="section" className={cx(classes.wrapper, className)} id={id}>
      <Container
        {...omit(containerProps, "className")}
        className={cx(classes.container, containerProps?.className)}
      >
        {children}
      </Container>
    </Box>
  );
};

export default NWONSection;
