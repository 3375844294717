import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import ResolvioGenerator from "../../../public/media/projects/resolvio/resolvio-beschlussgenerator.png";
import ResolvioHowTo from "../../../public/media/projects/resolvio/resolvio-how-to.png";
import { default as ResolvioLogo } from "../../../public/media/projects/resolvio/resolvio-logo.png";
import { default as ResolvioScreenshot } from "../../../public/media/projects/resolvio/resolvio-preview.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectResolvio = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.resolvio">("projects.resolvio");
  const germanT = i18n.getFixedT(Language.German, "projects.resolvio");
  const englishT = i18n.getFixedT(Language.English, "projects.resolvio");

  return useMemo(
    () => ({
      name: Project.Resolvio,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: ResolvioScreenshot,
      logo: ResolvioLogo,
      website: "https://resolvio.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [ResolvioScreenshot],
        steps: [
          {
            title: t("steps.step1.title"),
            description: t("steps.step1.description"),
            image: ResolvioHowTo,
          },
          {
            title: t("steps.step2.title"),
            description: t("steps.step2.description"),
            image: ResolvioGenerator,
          },
          {
            title: t("steps.step3.title"),
            description: t("steps.step3.description"),
            image: ResolvioScreenshot,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
