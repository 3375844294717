import { makeStyles } from "tss-react/mui";

export const useNWONFooterColumnStyles = makeStyles()((theme) => ({
  footerItem: {
    marginRight: theme.spacing(7),
    marginLeft: 0,
    "&:not(:last-child)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.only("md")]: {
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:last-of-type": {
      marginLeft: "auto",
      marginRight: 0,
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
        width: "100%",
      },
    },
  },
}));
