import { useCallback, useMemo } from "react";
import { NavigationItem } from "../interfaces/HeaderLink";
import { usePageInformationContext } from "../state/pageInformationContext/PageInformationContext";
import { useGetUrl } from "./urls/useGetUrl";

export const useLinkForNavigationItemCb = (): ((
  navigationItem: NavigationItem,
  urlParameters?: string | string[]
) => string) => {
  const getUrl = useGetUrl();
  const pageInformation = usePageInformationContext();

  return useCallback(
    (navigationItem: NavigationItem, urlParameters?: string | string[]) => {
      if (navigationItem.page) {
        return getUrl({
          page: navigationItem.page,
          hash: navigationItem.anchor,
          urlParameters: urlParameters,
        });
      }

      return getUrl({
        page: pageInformation.page,
        project: pageInformation.project,
        mainService: pageInformation.mainService,
        subService: pageInformation.subService,
        hash: navigationItem.anchor,
        urlParameters: urlParameters,
      });
    },
    [
      getUrl,
      pageInformation.mainService,
      pageInformation.page,
      pageInformation.project,
      pageInformation.subService,
    ]
  );
};

export const useLinkForNavigationItem = (
  navigationItem: NavigationItem,
  urlParameters?: string | string[]
): string => {
  const linkForItem = useLinkForNavigationItemCb();

  return useMemo(
    () => linkForItem(navigationItem, urlParameters),
    [linkForItem, navigationItem, urlParameters]
  );
};
