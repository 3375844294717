import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { NWONPage } from "../../config/NWONPage";

import { NavigationItem } from "../interfaces/HeaderLink";

export const useNavigationItems = (): NavigationItem[] => {
  const { t } = useTranslation<"common">();

  return useMemo(
    () => [
      {
        title: t("menuItems.services"),
        page: NWONPage.Services,
      },
      {
        title: t("menuItems.projects"),
        page: NWONPage.Projects,
      },
      {
        title: t("menuItems.team"),
        page: NWONPage.Team,
      },
      {
        anchor: t("menuItems.contact").toLowerCase(),
        title: t("menuItems.contact"),
      },
    ],
    [t]
  );
};
