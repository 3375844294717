import CheckIcon from "@mui/icons-material/Check";
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useNWONCTAIconListStyles } from "./NWONCTAIconListStyles";

const NWONCTAIconList = (): JSX.Element => {
  const { classes } = useNWONCTAIconListStyles();
  const { t } = useTranslation<"common">();

  return (
    <Box className={classes.wrapper}>
      <List>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <CheckIcon />
          </ListItemIcon>

          <Typography>{t("footer.iconList1")}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <CheckIcon />
          </ListItemIcon>

          <Typography>{t("footer.iconList2")}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <CheckIcon />
          </ListItemIcon>

          <Typography>{t("footer.iconList3")}</Typography>
        </ListItem>
      </List>
    </Box>
  );
};

export default NWONCTAIconList;
