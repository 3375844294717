import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";

export const useNWONCopyToClipboardButtonStyles = makeStyles()(() => ({
  hidden: {
    display: "none",
  },
  copyClipboardButton: {
    padding: important(0),
    borderLeft: "none",
    "&:hover": {
      borderLeft: "none",
    },
  },
  copyClipBoardIcon: {
    fontSize: "20px",
  },
  popperTooltipCopy: {},
  popperTooltipCopied: {
    marginTop: 0,
  },
}));
