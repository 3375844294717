import { makeStyles } from "tss-react/mui";

export const useNWONSectionStyles = makeStyles<{
  useBackgroundColorPrimaryMain: boolean;
  additionalPadding: boolean;
}>()((theme, { useBackgroundColorPrimaryMain, additionalPadding }) => ({
  wrapper: {
    backgroundColor: useBackgroundColorPrimaryMain
      ? theme.palette.primary.main
      : undefined,
    padding: additionalPadding ? theme.spacing(6, 0, 6, 6) : "",
    position: "relative",
    minHeight: "30vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(10),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
