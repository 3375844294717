/**
 * When 'lead' is set as a url parameter (without a value):
 *    - Menu items are hidden except for the language switcher and NWON logo
 *    - The projects in the hero section not clickable
 *
 * When 'ctaStyle=form' is set as an url parameter:
 *    - Clicking on 'Get offer now' button, a form is displayed
 *
 * When 'ctaStyle=contact-information' is set as a url parameter:
 *    - Clicking on 'Get offer now' button, it scrolls to the contact section (footer)
 */
export enum UrlParameter {
  Lead = "lead",
  CtaStyle = "cta-style",
}

export enum CtaStyleOption {
  Form = "form",
  ContactInformation = "contact",
}
