import { useMemo } from "react";
import AnsibleLogo from "../../../public/media/stacklogos/ansible-logo.png";
import DockerLogo from "../../../public/media/stacklogos/docker-logo.png";
import ExpresJsLogo from "../../../public/media/stacklogos/express-js-logo.png";
import GatsbyLogo from "../../../public/media/stacklogos/gatsby-logo.png";
import GitlabCiLogo from "../../../public/media/stacklogos/gitlab-logo.png";
import ShopifyHydrogenLogo from "../../../public/media/stacklogos/hydrogen-logo.png";
import JamstackLogo from "../../../public/media/stacklogos/jamstack-logo.png";
import KubernetesLogo from "../../../public/media/stacklogos/kubernetes-logo.png";
import NestJsLogo from "../../../public/media/stacklogos/nestjs-logo.png";
import PythonLogo from "../../../public/media/stacklogos/python-logo.png";
import RaidboxesLogo from "../../../public/media/stacklogos/raidboxes.png";
import ReactLogo from "../../../public/media/stacklogos/react-logo.png";
import ShopifyLogo from "../../../public/media/stacklogos/shopify-logo.png";
import SpacyLogo from "../../../public/media/stacklogos/spacy-logo.png";
import StrapiLogo from "../../../public/media/stacklogos/strapi-logo.png";
import TypescriptLogo from "../../../public/media/stacklogos/typescript-logo.png";
import VercelLogo from "../../../public/media/stacklogos/vercel-logo.png";
import WoocommerceLogo from "../../../public/media/stacklogos/woocommerce-logo.png";
import WordpressLogo from "../../../public/media/stacklogos/wordpress-logo.png";
import { LogoInformation } from "../../components/01-atoms/NWONLogoGrid/NWONLogoGridInterface";
import { DjangoLogo } from "../../__generated__/svgComponents/DjangoLogo";
import { NextjsLogo } from "../../__generated__/svgComponents/NextjsLogo";
import { NodejsLogo } from "../../__generated__/svgComponents/NodejsLogo";

export enum Tool {
  Django = "Django",
  Spacy = "Spacy",
  ExpressJs = "ExpressJs",
  TypeScript = "TypeScript",
  Python = "Python",
  NodeJs = "NodeJs",
  NextJs = "NextJs",
  Gatsby = "Gatsby",
  Raidbox = "Raidbox",
  WordPress = "WordPress",
  Shopify = "Shopify",
  Woocommerce = "Woocommerce",
  Ansible = "Ansible",
  Docker = "Docker",
  React = "React",
  Gitlab = "Gitlab",
  ShopifyHydrogen = "ShopifyHydrogen",
  Kubernetes = "Kubernetes",
  Vercel = "Vercel",
  JamStack = "JamStack",
  NestJs = "NestJs",
  Strapi = "Strapi",
}

export const useToolMap = (): Record<Tool, LogoInformation> =>
  useMemo(
    () => ({
      [Tool.Django]: {
        image: <DjangoLogo />,
        link: "https://www.djangoproject.com/",
        tooltip: "Django",
      },
      [Tool.Python]: {
        image: PythonLogo,
        link: "https://www.python.org/",
        tooltip: "Python",
      },
      [Tool.TypeScript]: {
        image: TypescriptLogo,
        link: "https://www.typescriptlang.org/",
        tooltip: "TypeScript",
      },
      [Tool.NodeJs]: {
        image: <NodejsLogo />,
        link: "https://nodejs.org/",
        tooltip: "Node.js",
      },
      [Tool.Raidbox]: {
        image: RaidboxesLogo,
        link: "https://raidboxes.io?aid=47227",
        tooltip: "Raidboxes",
      },
      [Tool.NextJs]: {
        image: <NextjsLogo />,
        link: "https://nextjs.org/",
        tooltip: "Next.js",
      },
      [Tool.WordPress]: {
        image: WordpressLogo,
        link: "https://wordpress.com/de/",
        tooltip: "WordPress",
      },
      [Tool.Shopify]: {
        image: ShopifyLogo,
        link: "https://www.shopify.de/",
        tooltip: "Shopify",
      },
      [Tool.Woocommerce]: {
        image: WoocommerceLogo,
        link: "https://woocommerce.com/",
        tooltip: "WooCommerce",
      },
      [Tool.Ansible]: {
        image: AnsibleLogo,
        link: "https://www.ansible.com/",
        tooltip: "Ansible",
      },
      [Tool.Docker]: {
        image: DockerLogo,
        link: "https://www.docker.com/",
        tooltip: "Docker",
      },
      [Tool.React]: {
        image: ReactLogo,
        link: "https://reactjs.org/",
        tooltip: "React",
      },
      [Tool.Gitlab]: {
        image: GitlabCiLogo,
        link: "https://docs.gitlab.com/ee/ci/",
        tooltip: "Gitlab CI/CD Pipelines",
      },
      [Tool.ShopifyHydrogen]: {
        image: ShopifyHydrogenLogo,
        link: "https://hydrogen.shopify.dev/",
        tooltip: "Shopify Hydrogen",
      },
      [Tool.Kubernetes]: {
        image: KubernetesLogo,
        link: "https://kubernetes.io/de/",
        tooltip: "Kubernets",
      },
      [Tool.Vercel]: {
        image: VercelLogo,
        link: "https://vercel.com/",
        tooltip: "Vercel",
      },
      [Tool.JamStack]: {
        image: JamstackLogo,
        link: "https://jamstack.org/",
        tooltip: "Jamstack",
      },
      [Tool.Spacy]: {
        image: SpacyLogo,
        link: "https://spacy.io/",
        tooltip: "Spacy",
      },
      [Tool.NestJs]: {
        image: NestJsLogo,
        link: "https://nestjs.com/",
        tooltip: "NestJS",
      },
      [Tool.Strapi]: {
        image: StrapiLogo,
        link: "https://strapi.io/",
        tooltip: "Strapi",
      },
      [Tool.Gatsby]: {
        image: GatsbyLogo,
        link: "https://www.gatsbyjs.com/",
        tooltip: "Gatsby",
      },
      [Tool.ExpressJs]: {
        image: ExpresJsLogo,
        link: "https://expressjs.com/de/",
        tooltip: "Express",
      },
    }),
    []
  );
