import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as ConconIntro } from "../../../public/media/projects/concon/concon-intro.png";
import { default as ConconLogo } from "../../../public/media/projects/concon/concon-logo.png";
import { default as ConconPreview } from "../../../public/media/projects/concon/concon-preview.png";
import { default as ConconStage } from "../../../public/media/projects/concon/concon-stage.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectConcon = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.concon">("projects.concon");
  const germanT = i18n.getFixedT(Language.German, "projects.concon");
  const englishT = i18n.getFixedT(Language.English, "projects.concon");

  return useMemo(
    () => ({
      name: Project.ConCon2021,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: ConconPreview,
      logo: ConconLogo,
      website: "https://contentconvention.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("projects.concon.slug"),
        [Language.English]: englishT("projects.concon.slug"),
      },
      details: {
        heroImages: [ConconPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: ConconStage,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: ConconIntro,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
