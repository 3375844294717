import { useMemo } from "react";
import { SubService } from "../../interfaces/SubService";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";

type DefaultDetailPageForSubServiceMap = Record<
  SubService,
  SubServiceDetailPage | undefined
>;

/**
 * Defines the default parent SubService for each SubServiceDetailPage
 * */
export const defaultDetailPageForSubServiceMap: DefaultDetailPageForSubServiceMap =
  {
    [SubService.Api]: undefined,
    [SubService.Backend]: SubServiceDetailPage.Backend,
    [SubService.CookieConsent]: undefined,
    [SubService.CorporateWebsites]: SubServiceDetailPage.CorporateWebsite,
    [SubService.Deployment]: SubServiceDetailPage.Deployment,
    [SubService.DeploymentStrategies]: SubServiceDetailPage.Deployment,
    [SubService.DeveloperExperience]: undefined,
    [SubService.Docker]: SubServiceDetailPage.Docker,
    [SubService.EventWebsites]: SubServiceDetailPage.CorporateWebsite,
    [SubService.JamStack]: SubServiceDetailPage.CorporateWebsite,
    [SubService.MatchingPlatform]: undefined,
    [SubService.OnlineShops]: SubServiceDetailPage.Shop,
    [SubService.ProcessOptimization]: undefined,
    [SubService.Frontend]: SubServiceDetailPage.Frontend,
    [SubService.Saas]: undefined,
    [SubService.SeoOptimization]: undefined,
    [SubService.SoftwareArchitecture]: undefined,
    [SubService.UserTracking]: undefined,
    [SubService.WordPress]: SubServiceDetailPage.CorporateWebsite,
  };

/**
 * Get a map that defines the default parent SubService for each SubServiceDetailPage
 * */
export const useDefaultDetailPageForSubServiceMap =
  (): DefaultDetailPageForSubServiceMap =>
    useMemo(() => defaultDetailPageForSubServiceMap, []);
