import { makeStyles } from "tss-react/mui";

export const useEmojiStyles = makeStyles()(() => ({
  emoji: {
    verticalAlign: "middle",
    "&img": {
      width: "1em",
      height: "1em",
    },
  },
}));
