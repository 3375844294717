import { Duration, Easing, Theme } from "@mui/material";
import { CSSProperties } from "tss-react/tools/types/CSSObject";

export const defaultTransition = (
  theme: Theme,
  props: string | string[],
  duration: keyof Duration = "standard",
  easing: keyof Easing = "easeInOut"
): CSSProperties => {
  const propsArray = typeof props === "string" ? [props] : props;
  return {
    transition: theme.transitions.create(propsArray, {
      duration: theme.transitions.duration[duration],
      easing: theme.transitions.easing[easing],
    }),
  };
};
