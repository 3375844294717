import { useProjectMapping } from "../data/projects/useAllProjects";
import { useMainServiceMap } from "../data/services/useMainServiceMap";
import { useServiceDetailMap } from "../data/sub-service/useServiceDetailMap";
import { usePageInformationContext } from "../state/pageInformationContext/PageInformationContext";
import { usePageInformationMap } from "./usePagePathMap";

export const useMetaTitle = (): string => {
  const { mainService, project, page, serviceDetailPage } =
    usePageInformationContext();

  const mainServiceMap = useMainServiceMap();
  const serviceDetailPageMap = useServiceDetailMap();
  const projectMap = useProjectMapping();
  const pageData = usePageInformationMap()[page];

  if (project) {
    return `${projectMap[project].metaInformation.title} - NWON`;
  }

  if (serviceDetailPage && serviceDetailPageMap[serviceDetailPage]) {
    return `${
      serviceDetailPageMap[serviceDetailPage]?.metaInformation.title || ""
    } - NWON`;
  }

  if (mainService) {
    return `${mainServiceMap[mainService].metaInformation.title} - NWON`;
  }

  return pageData.metaInformation.title;
};
