import { initializeDataLayer } from "./startGoogleTagManager";

/**
 * The event values must match events in google tag manager.
 */
export enum NWONTrackingEvent {
  AdConversion = "adConversion",
  SamDockLeadFormSendButtonClicked = "samDockLeadFormSendButtonClicked",
}

export const trackEvent = (event: NWONTrackingEvent): void => {
  initializeDataLayer();
  window.dataLayer.push({ event });
};
