import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  itemVariant,
  variants,
} from "../../../../helpers/clientSide/mobileHeaderConfig";
import { useLinkForNavigationItemCb } from "../../../../hooks/useLinkForNavigationItem";
import { useNavigationItems } from "../../../../hooks/useNavigationItems";
import { NavigationItem } from "../../../../interfaces/HeaderLink";
import { usePageInformationContext } from "../../../../state/pageInformationContext/PageInformationContext";
import NWONLanguageSwitcher from "../../../02-molecules/NWONLanguageSwitcher/NWONLanguageSwitcher";
import { NWONMobileNavigationProps } from "./NWONHeaderMobileInterface";
import { useNWONHeaderMobileStyles } from "./NWONHeaderMobileStyles";

export const NWONMobileNavigation = (
  props: NWONMobileNavigationProps
): JSX.Element => {
  const { toggleOpen } = props;
  const { classes } = useNWONHeaderMobileStyles();
  const router = useRouter();
  const { locale } = router;
  const linkForItem = useLinkForNavigationItemCb();

  const page = usePageInformationContext().page;

  const navigationItems = useNavigationItems();

  const [loadList, setLoadList] = useState(false);

  const handleClick = (item: NavigationItem) => {
    const url = linkForItem(item);

    void router.push(url, url, {
      locale,
    });
    toggleOpen();
    setLoadList(!loadList);
  };

  return (
    <motion.ul variants={variants} className={classes.list}>
      {navigationItems.map((navigationItem) => (
        <motion.li
          variants={itemVariant}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          key={navigationItem.title}
          className={classes.listItem}
        >
          <Typography
            variant="body1"
            component="a"
            className={
              navigationItem.page === page
                ? classes.linkTextActive
                : classes.linkText
            }
            onClick={() => handleClick(navigationItem)}
          >
            {navigationItem.title}
          </Typography>
        </motion.li>
      ))}

      <motion.li variants={itemVariant} className={classes.listItem}>
        <NWONLanguageSwitcher />
      </motion.li>
    </motion.ul>
  );
};
