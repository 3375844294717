import { UseMeasureRect } from "react-use/lib/useMeasure";
import { NWONBubbleImageProps } from "../../01-atoms/NWONBubbleImage/NWONBubbleImageInterfaces";

export enum ParallaxEffectDirection {
  TopToBottom = "top-to-bottom",
  BottomToTop = "bottom-to-top",
}

export enum BubblePageSection {
  TeamSection = "team-section",
  ContactSection = "contact-section",
}

export type TeamClasses =
  | "bubbleWrapper"
  | "bubbleCarina"
  | "bubbleChristopher"
  | "bubbleStefan"
  | "bubbleReik"
  | "bubbleNWON"
  | "bubbleFelix"
  | "bubblePlant01"
  | "bubblePlant02"
  | "bubblePlant03";

export type ContactClasses =
  | "bubbleWrapper"
  | "bubbleNWON"
  | "bubbleStefan"
  | "bubbleReik"
  | "bubbleFelix";

export interface NWONFloatingBubblesProps {
  // The measurements of the container surrounding this component.
  measuresSurroundingBox: UseMeasureRect;
  /*
   * The amount of distance the element, which is measured for
   * achieving the parallax effect, has to the top of the page.
   */
  offsetTopOfPageRefCurrent: HTMLDivElement | undefined;
  // The direction of the parallax effect
  direction?: ParallaxEffectDirection;
  // The page section where the component is used
  pageSection: BubblePageSection;
}

export interface NWONFloatingBubblesViewModel {
  bubbleWrapperClass: string;
  bubbles: NWONBubbleImageProps[];
}
interface NWONFloatingBubblesViewModelPropsTeam
  extends NWONFloatingBubblesProps {
  pageSection: BubblePageSection.TeamSection | BubblePageSection.ContactSection;
  classes: Record<TeamClasses, string>;
}

interface NWONFloatingBubblesViewModelPropsContact
  extends NWONFloatingBubblesProps {
  pageSection: BubblePageSection.ContactSection;
  classes: Record<ContactClasses, string>;
}

interface NWONFloatingBubblesViewModelPropsOther
  extends NWONFloatingBubblesProps {
  classes: Record<string, string>;
}

export type NWONFloatingBubblesViewModelProps =
  | NWONFloatingBubblesViewModelPropsTeam
  | NWONFloatingBubblesViewModelPropsContact
  | NWONFloatingBubblesViewModelPropsOther;
