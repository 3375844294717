import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import VitisLogo from "../../../public/media/projects/vitis/vitis-logo.svg";
import VitisMap from "../../../public/media/projects/vitis/vitis-map.png";
import VitisPreview from "../../../public/media/projects/vitis/vitis-preview.png";
import VitisResearch from "../../../public/media/projects/vitis/vitis-research.png";
import VitisSteps from "../../../public/media/projects/vitis/vitis-steps.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectVitis = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.vitis">("projects.vitis");
  const germanT = i18n.getFixedT(Language.German, "projects.vitis");
  const englishT = i18n.getFixedT(Language.English, "projects.vitis");

  return useMemo(
    () => ({
      name: Project.Vitis,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: VitisPreview,
      logo: <VitisLogo />,
      website: "https://vitis-healthcare.com",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [VitisPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: VitisResearch,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: VitisMap,
          },
          {
            title: t("steps.3.title"),
            description: t("steps.3.description"),
            image: VitisSteps,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
