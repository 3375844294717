import { useCallback } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { useI18nActiveLanguage } from "../useI18nActiveLanguage";

export const useLanguageUrlComponentCb = (): ((
  language?: Language
) => string | undefined) => {
  const languageState = useI18nActiveLanguage();

  return useCallback(
    (language?: Language) => {
      const languageToCheck = language ? Language : languageState;

      if (languageToCheck === Language.English) {
        return "en";
      }

      return undefined;
    },
    [languageState]
  );
};

export const useLanguageUrlComponent = (): string | undefined =>
  useLanguageUrlComponentCb()();
