import { makeStyles } from "tss-react/mui";

export const useNWONHeaderMobileStyles = makeStyles()((theme) => ({
  nav: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
  },
  background: {
    position: "absolute",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    bottom: 0,
    background: theme.palette.background.default,
    boxShadow: "none",
    zIndex: 2,
  },
  toggleButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    border: "none",
    cursor: "pointer",
    position: "absolute",
    padding: 0,
    right: 0,
    borderRadius: "50%",
    background: "transparent !important",
    boxShadow: "none !important",
    zIndex: 3,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
      marginRight: theme.spacing(2),
    },
  },
  listItem: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    columnGap: theme.spacing(1),
    "& a": {
      cursor: "pointer",
    },
  },
  list: {
    margin: 0,
    padding: "25px",
    position: "absolute",
    top: "100px",
    width: "100%",
    zIndex: 3,
  },
  languageImageNonActive: {
    opacity: "0.3",
  },
  linkText: {
    color: theme.palette.grey[600],
    textDecoration: "none",
  },
  linkTextActive: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
}));
