import clsx from "clsx";
import { replaceEmojis } from "../../../helpers/clientSide/replaceEmojis";
import { useEmojiStyles } from "./useEmojiStyles";

interface GenericEmojiProps {
  /**
   * Will be used as the aria-description
   */
  description: string;
  /**
   * The actual emoji
   */
  emoji: string;
  className?: string;
  // We put this to prevent passing children.
  // eslint-disable-next-line react/no-unused-prop-types
  children?: undefined | null;
}

export const GenericEmoji = (props: GenericEmojiProps): JSX.Element => {
  const { classes } = useEmojiStyles();

  return (
    <span
      className={clsx(classes.emoji, props.className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: replaceEmojis(props.emoji),
      }}
      role="img"
      aria-label={props.description}
    />
  );
};
