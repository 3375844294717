import { randomIntBetween } from "@nvon/baseline";
import { makeStyles } from "tss-react/mui";
import { NWONBubbleImageStylesProps } from "./NWONBubbleImageInterfaces";

const minSize = 80;
const maxSize = 130;
const highlightedSize = 140;

const minSizeMd = 85;
const maxSizeMd = 120;
const highlightedSizeMd = 140;

const minSizeMobile = 75;
const maxSizeMobile = 100;
const highlightedSizeMobile = 100;

export const useNWONBubbleImageStyles =
  makeStyles<NWONBubbleImageStylesProps>()((theme, props) => ({
    wrapper: {
      position: "absolute",
      width: props.isHighlighted
        ? highlightedSize
        : randomIntBetween(minSize, maxSize),
      [theme.breakpoints.down("md")]: {
        width: props.isHighlighted
          ? highlightedSizeMd
          : randomIntBetween(minSizeMd, maxSizeMd),
      },
      [theme.breakpoints.down("sm")]: {
        width: props.isHighlighted
          ? highlightedSizeMobile
          : randomIntBetween(minSizeMobile, maxSizeMobile),
      },
    },
    image: {
      overflow: "hidden !important",
      borderRadius: "50%",
      boxShadow: theme.shadows[7],
    },
  }));
