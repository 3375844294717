import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NWONAddress = (): JSX.Element => {
  const { t } = useTranslation<"common">();

  return (
    <Link href={t("google.link")} target="_blank">
      <Typography variant="body2" color="secondary">
        {`${t("contactInformation.address.companyName")}`}
      </Typography>

      <Typography variant="body2" color="secondary">
        {`${t("contactInformation.address.street")} ${t(
          "contactInformation.address.streetNumber"
        )}`}
      </Typography>

      <Typography variant="body2" color="secondary">
        {`${t("contactInformation.address.postalCode")} ${t(
          "contactInformation.address.city"
        )}`}
      </Typography>

      <Typography variant="body2" color="secondary">
        {t("contactInformation.address.country")}
      </Typography>
    </Link>
  );
};
