import { Box, NoSsr, useScrollTrigger } from "@mui/material";
import { UrlParameter } from "../../../../../config/GoogleAdConfiguration";
import { scrollTriggerConstant } from "../../../../helpers/clientSide/constants";
import { useHasUrlParameter } from "../../../../hooks/urls/useHasUrlParameter";
import { useInitializedMediaQuery } from "../../../../hooks/useInitializedMediaQuery";
import { useNavigationItems } from "../../../../hooks/useNavigationItems";
import theme, { mobileMenuBreakpoint } from "../../../../layouts/theme";
import NWONHeaderLink from "../../../02-molecules/NWONHeaderLink/NWONHeaderLink";
import NWONLanguageSwitcher from "../../../02-molecules/NWONLanguageSwitcher/NWONLanguageSwitcher";

import { useNWONHeaderDesktopStyles } from "./NWONHeaderDesktopStyles";

const NWONHeaderDesktop = (): JSX.Element => {
  const { classes, cx } = useNWONHeaderDesktopStyles();
  const navItems = useNavigationItems();

  const { isMatching: isMobile } = useInitializedMediaQuery(
    theme.breakpoints.down(mobileMenuBreakpoint)
  );
  const isPageScrolled = useScrollTrigger(scrollTriggerConstant(isMobile));
  const hasLeadUrlParameter = useHasUrlParameter(UrlParameter.Lead);

  return (
    <Box>
      <ul
        className={cx(
          classes.headerNavLinkList,
          classes.headerNavTop,
          hasLeadUrlParameter === true && classes.headerNavLead
        )}
      >
        {hasLeadUrlParameter === false &&
          navItems.map((navigationItem) => (
            <li className={classes.headerNavLi} key={navigationItem.title}>
              <NoSsr>
                <NWONHeaderLink
                  isPageScrolled={isPageScrolled}
                  navigationItem={navigationItem}
                >
                  {navigationItem.title}
                </NWONHeaderLink>
              </NoSsr>
            </li>
          ))}

        <li className={cx(classes.headerNavLi, classes.headerLanguageSwitcher)}>
          <NWONLanguageSwitcher />
        </li>
      </ul>
    </Box>
  );
};

export default NWONHeaderDesktop;
