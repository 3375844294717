import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import bouldernPfalzBoard from "../../../public/media/projects/bouldern-pfalz/bouldern-pfalz-board.png";
import bouldernPfalzLogo from "../../../public/media/projects/bouldern-pfalz/bouldern-pfalz-logo.jpg";
import bouldernPfalzPreview from "../../../public/media/projects/bouldern-pfalz/bouldern-pfalz-preview.png";
import bouldernPfalzWebapp from "../../../public/media/projects/bouldern-pfalz/bouldern-pfalz-webapp.png";
import FlarumLogo from "../../../public/media/stacklogos/flarum.svg";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectBouldernPfalz = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.bouldernPfalz">(
    "projects.bouldernPfalz"
  );
  const germanT = i18n.getFixedT(Language.German, "projects.bouldernPfalz");
  const englishT = i18n.getFixedT(Language.English, "projects.bouldernPfalz");

  return useMemo(
    () => ({
      name: Project.BouldernPfalz,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: bouldernPfalzPreview,
      logo: bouldernPfalzLogo,
      website: "https://bouldern-pfalz.de/",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [bouldernPfalzPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: bouldernPfalzBoard,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: bouldernPfalzWebapp,
          },
          {
            title: t("steps.3.title"),
            description: t("steps.3.description"),
            image: <FlarumLogo />,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
