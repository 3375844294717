import { ComponentWithOptionalClassNameProps } from "../../../interfaces/reactComponentInterfaces";
import { GenericEmoji } from "./GenericEmoji";

export type EmojiProps = ComponentWithOptionalClassNameProps;

export type EmojiComponent = (props: EmojiProps) => JSX.Element;

/**
 * Function to create an emoji component.
 */
export const createEmoji = (
  emoji: string,
  description: string
): EmojiComponent => {
  const EmojiComponent: EmojiComponent = (props): JSX.Element => (
    <GenericEmoji
      className={props.className}
      emoji={emoji}
      description={description}
    />
  );
  return EmojiComponent;
};
