import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import BnBBackend from "../../../public/media/projects/bnb/bnb-backend.png";
import BnBList from "../../../public/media/projects/bnb/bnb-list.png";
import BnBLogo from "../../../public/media/projects/bnb/bnb-logo.png";
import BnBMatch from "../../../public/media/projects/bnb/bnb-match.png";
import BnBPreview from "../../../public/media/projects/bnb/bnb-preview.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectBnbHotels = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.bnb">("projects.bnb");
  const germanT = i18n.getFixedT(Language.German, "projects.bnb");
  const englishT = i18n.getFixedT(Language.English, "projects.bnb");

  return useMemo(
    () => ({
      name: Project.Bnbhotels,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: BnBPreview,
      logo: BnBLogo,
      website: "https://bb-hoteljobs.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [BnBPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: BnBMatch,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: BnBList,
          },
          {
            title: t("steps.3.title"),
            description: t("steps.3.description"),
            image: BnBBackend,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
