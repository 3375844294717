import { useLanguageUrlComponent } from "./useLanguageUrlComponent";

export const useRootUrl = (): string => {
  const language = useLanguageUrlComponent();

  if (language) {
    return `/${language}/`;
  }

  return "/";
};
