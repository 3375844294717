import { Box, Grid } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRef } from "react";
import { useMeasure } from "react-use";
import NWONContactSection from "../NWONContactSection/NWONContactSection";
import NWONSection from "../NWONSection/NWONSection";
import { NWONAddress } from "./NWONAddress/NWONAddress";
import { NWONContact } from "./NWONContact/NWONContact";
import { NWONFooterColumn } from "./NWONFooterColumn/NWONFooterColumn";
import { NWONFooterServiceList } from "./NWONFooterServiceList/NWONFooterServiceList";
import { useNWONFooterStyles } from "./NWONFooterStyles";
import { NWONPageList } from "./NWONPageList/NWONPageList";
import { NWONSocialMediaIcons } from "./NWONSocialMediaIcons/NWONSocialMediaIcons";

const NWONFooter = (): JSX.Element => {
  const { t } = useTranslation<"common">();
  const { classes } = useNWONFooterStyles();
  const [ref, measures] = useMeasure();
  const offsetRefTopOfPage = useRef<HTMLDivElement>();

  return (
    <Box ref={offsetRefTopOfPage}>
      <Box ref={ref}>
        <NWONSection className={classes.sectionWrapper}>
          <Box className={classes.contactWrapper}>
            <NWONContactSection
              measures={measures}
              offsetTopOfPageRefCurrent={offsetRefTopOfPage?.current}
            />
          </Box>

          <Box className={classes.footerWrapper}>
            <Grid container={true} className={classes.footerContainer}>
              <NWONFooterColumn
                title={t("footer.contact.title")}
                subTitleElement={
                  <>
                    <NWONContact />

                    <NWONFooterColumn
                      title={t("footer.address.title")}
                      subTitleElement={<NWONAddress />}
                      gridWrapperClassName={classes.addressColumn}
                    />
                  </>
                }
              />

              <NWONFooterColumn
                title={t("footer.landingpages.title")}
                subTitleElement={<NWONFooterServiceList />}
              />

              <NWONFooterColumn
                title={t("footer.aboutNwon.title")}
                subTitleElement={<NWONPageList />}
              />

              <NWONFooterColumn
                title={t("footer.followUs")}
                subTitleElement={<NWONSocialMediaIcons />}
                gridWrapperClassName={classes.followUsColumn}
              />
            </Grid>
          </Box>
        </NWONSection>
      </Box>
    </Box>
  );
};

export default NWONFooter;
