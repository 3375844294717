import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";
import { mobileMenuBreakpoint } from "../../../../layouts/theme";

export const useNWONHeaderDesktopStyles = makeStyles()((theme) => ({
  headerNavLinkList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: 0,
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      flexDirection: "column",
    },
  },
  headerNavTop: {
    flex: 1,
    justifyContent: "center",
  },
  headerNavLi: {
    listStyle: "none",
    marginLeft: theme.spacing(4),
    "& > a": {
      marginBottom: 0,
      inlineSize: "max-content",
      textDecoration: "none",
      "& > span": {
        marginBottom: 0,
        textAlign: "center",
        "& > p": {
          padding: 0,
        },
      },
    },

    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(1.5),
    },
    [theme.breakpoints.down(mobileMenuBreakpoint)]: {
      width: "100%",
    },
  },
  headerLanguageSwitcher: {
    display: "inline-flex",
    columnGap: theme.spacing(1),
  },
  headerNavLead: {
    alignItems: important("flex-start"),
  },
}));
