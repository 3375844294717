import { Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import Image from "next/image";
import {
  mobileDevices,
  upToMediumDevices,
} from "../../../helpers/clientSide/constants";
import {
  NWONBubbleImageProps,
  NWONBubbleImageStylesProps,
} from "./NWONBubbleImageInterfaces";
import { useNWONBubbleImageStyles } from "./NWONBubbleImageStyles";

const NWONBubbleImage = (props: NWONBubbleImageProps): JSX.Element => {
  const stylesProps: NWONBubbleImageStylesProps = {
    isHighlighted:
      props.isHighlighted === undefined ? false : props.isHighlighted,
  };
  const { classes, cx } = useNWONBubbleImageStyles(stylesProps);

  const bubbleImage: JSX.Element = (
    <motion.div
      className={cx(props.wrapperClassName, classes.wrapper)}
      style={{ y: props.yPosition }}
      whileHover={{ scale: 1.07 }}
    >
      <Image
        src={props.image}
        loading="eager"
        alt="team-member"
        placeholder="blur"
        layout="responsive"
        sizes={`${mobileDevices} 30vw, ${upToMediumDevices} 20vw, 10vw`}
        className={classes.image}
      />
    </motion.div>
  );

  return props.tooltip ? (
    <Tooltip arrow={true} title={props.tooltip}>
      {bubbleImage}
    </Tooltip>
  ) : (
    bubbleImage
  );
};

export default NWONBubbleImage;
