import {
  BubblePageSection,
  NWONFloatingBubblesProps,
  NWONFloatingBubblesViewModel,
} from "./NWONFloatingBubblesInterfaces";
import { useNWONFloatingContactBubblesStyles } from "./NWONFloatingContactBubbles/NWONFloatingContactBubblesStyles";
import { useNWONFloatingTeamBubblesStyles } from "./NWONFloatingTeamBubbles/NWONFloatingTeamBubblesStyles";
import { useNWONFloatingBubbles } from "./useNWONFloatingBubbles";

export const useNWONFloatingBubblesViewModel = (
  props: NWONFloatingBubblesProps
): NWONFloatingBubblesViewModel => {
  const { classes: teamClasses } = useNWONFloatingTeamBubblesStyles();
  const { classes: contactClasses } = useNWONFloatingContactBubblesStyles();

  const bubbles = useNWONFloatingBubbles({
    ...props,
    classes:
      props.pageSection === BubblePageSection.TeamSection
        ? teamClasses
        : contactClasses,
  });

  return {
    bubbleWrapperClass:
      BubblePageSection.ContactSection === props.pageSection
        ? contactClasses.bubbleWrapper
        : teamClasses.bubbleWrapper,
    bubbles: bubbles,
  };
};
