import { useMemo } from "react";
import { Project, ProjectData } from "../../interfaces/project";
import { useProjectBnbHotels } from "./bnbhotels";
import { useProjectBouldernPfalz } from "./bouldernPfalz";
import { useProjectConcon } from "./concon";
import { useProjectDeineInsel } from "./deineInsel";
import { useProjectEvolvingRegions } from "./evolvingRegions";
import { useProjectKsv } from "./ksv";
import { useProjectMedihead } from "./medihead";
import { useProjectPicwise } from "./picwise";
import { useProjectResolvio } from "./resolvio";
import { useProjectRheinelement } from "./rheinelement";
import { useProjectVitis } from "./vitis";

export const useAllProjects = (): ProjectData[] => {
  const deineInsel = useProjectDeineInsel();
  const resolvio = useProjectResolvio();
  const bnb = useProjectBnbHotels();
  const medihead = useProjectMedihead();
  const picwise = useProjectPicwise();
  const rheinelement = useProjectRheinelement();
  const ksv = useProjectKsv();
  const evolvingRegions = useProjectEvolvingRegions();
  const vitis = useProjectVitis();
  const concon = useProjectConcon();
  const bouldernPfalz = useProjectBouldernPfalz();

  return useMemo(
    () => [
      deineInsel,
      resolvio,
      bnb,
      medihead,
      picwise,
      rheinelement,
      ksv,
      evolvingRegions,
      vitis,
      concon,
      bouldernPfalz,
    ],
    [
      deineInsel,
      bnb,
      bouldernPfalz,
      concon,
      evolvingRegions,
      ksv,
      medihead,
      picwise,
      resolvio,
      rheinelement,
      vitis,
    ]
  );
};

export const useProjectMapping = (): Record<Project, ProjectData> => {
  const deineInsel = useProjectDeineInsel();
  const resolvio = useProjectResolvio();
  const bnb = useProjectBnbHotels();
  const medihead = useProjectMedihead();
  const picwise = useProjectPicwise();
  const rheinelement = useProjectRheinelement();
  const ksv = useProjectKsv();
  const evolvingRegions = useProjectEvolvingRegions();
  const vitis = useProjectVitis();
  const concon = useProjectConcon();
  const bouldernPfalz = useProjectBouldernPfalz();

  return useMemo(
    () => ({
      [Project.DeineInsel]: deineInsel,
      [Project.Resolvio]: resolvio,
      [Project.Bnbhotels]: bnb,
      [Project.Medihead]: medihead,
      [Project.Picwise]: picwise,
      [Project.Rheinelement]: rheinelement,
      [Project.Ksv]: ksv,
      [Project.EvolvingRegions]: evolvingRegions,
      [Project.Vitis]: vitis,
      [Project.ConCon2021]: concon,
      [Project.BouldernPfalz]: bouldernPfalz,
    }),
    [
      deineInsel,
      bnb,
      bouldernPfalz,
      concon,
      evolvingRegions,
      ksv,
      medihead,
      picwise,
      resolvio,
      rheinelement,
      vitis,
    ]
  );
};
