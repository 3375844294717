import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../config/LanguageConfiguration";
import { NWONPage } from "../../config/NWONPage";
import { NWONPageInformation } from "../interfaces/NWONPageInformation";

export const usePageInformationMap = (): Record<
  NWONPage,
  NWONPageInformation
> => {
  const { t, i18n } = useTranslation<"pages">("pages");
  const germanT = i18n.getFixedT(Language.German, "pages");
  const englishT = i18n.getFixedT(Language.English, "pages");

  return useMemo(
    () => ({
      [NWONPage.Home]: {
        slug: t("homepage.slug"),
        slugs: {
          [Language.German]: germanT("homepage.slug"),
          [Language.English]: englishT("homepage.slug"),
        },
        metaInformation: {
          title: t("homepage.meta.title"),
          description: t("homepage.meta.description"),
        },
      },
      [NWONPage.NotFound]: {
        slug: t("notFound.slug"),
        slugs: {
          [Language.German]: germanT("notFound.slug"),
          [Language.English]: englishT("notFound.slug"),
        },
        metaInformation: {
          title: t("notFound.meta.title"),
          description: t("notFound.meta.description"),
        },
      },
      [NWONPage.Projects]: {
        slug: t("projects.slug"),
        slugs: {
          [Language.German]: germanT("projects.slug"),
          [Language.English]: englishT("projects.slug"),
        },
        metaInformation: {
          title: t("projects.meta.title"),
          description: t("projects.meta.description"),
        },
      },
      [NWONPage.Team]: {
        slug: t("team.slug"),
        slugs: {
          [Language.German]: germanT("team.slug"),
          [Language.English]: englishT("team.slug"),
        },
        metaInformation: {
          title: t("team.meta.title"),
          description: t("team.meta.description"),
        },
      },
      [NWONPage.Services]: {
        slug: t("service.slug"),
        slugs: {
          [Language.German]: germanT("service.slug"),
          [Language.English]: englishT("service.slug"),
        },
        metaInformation: {
          title: t("service.meta.title"),
          description: t("service.meta.description"),
        },
      },
      [NWONPage.Imprint]: {
        slug: t("imprint.slug"),
        slugs: {
          [Language.German]: germanT("imprint.slug"),
          [Language.English]: englishT("imprint.slug"),
        },
        metaInformation: {
          title: t("imprint.meta.title"),
          description: t("imprint.meta.description"),
        },
      },
      [NWONPage.PrivacyPolicy]: {
        slug: t("dataProtection.slug"),
        slugs: {
          [Language.German]: germanT("dataProtection.slug"),
          [Language.English]: englishT("dataProtection.slug"),
        },
        metaInformation: {
          title: t("dataProtection.meta.title"),
          description: t("dataProtection.meta.description"),
        },
      },
    }),
    [englishT, germanT, t]
  );
};
