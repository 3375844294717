import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { UrlParameter } from "../../../../config/GoogleAdConfiguration";
import { useHasUrlParameter } from "../../../hooks/urls/useHasUrlParameter";
import { useLinkForNavigationItem } from "../../../hooks/useLinkForNavigationItem";
import { useRouterActiveLanguage } from "../../../hooks/useRouterActiveLanguage";
import { usePageInformationContext } from "../../../state/pageInformationContext/PageInformationContext";
import Link from "../../01-atoms/Link";

import {
  NWONHeaderLinkProps,
  NWONHeaderLinkStylesProps,
} from "./NWONHeaderLinkInterfaces";
import { useNWONHeaderLinkStyles } from "./NWONHeaderLinkStyles";

const NWONHeaderLink = (props: NWONHeaderLinkProps): JSX.Element => {
  const { navigationItem, children, isPageScrolled } = props;
  const locale = useRouterActiveLanguage();
  const { page } = usePageInformationContext();

  const hasCtaStyleUrlParameter = useHasUrlParameter(UrlParameter.CtaStyle);
  const url = useLinkForNavigationItem(
    navigationItem,
    hasCtaStyleUrlParameter ? UrlParameter.CtaStyle : undefined
  );

  const stylesProps: NWONHeaderLinkStylesProps = useMemo(
    () => ({ isPageScrolled, isActive: navigationItem.page === page }),
    [isPageScrolled, page, navigationItem.page]
  );

  const { classes } = useNWONHeaderLinkStyles(stylesProps);

  return (
    <motion.div whileHover={{ scale: 1.07 }} whileTap={{ scale: 0.95 }}>
      <Link href={url} locale={locale}>
        <Typography variant="body1" className={classes.headerLinkTypography}>
          {children}
        </Typography>
      </Link>
    </motion.div>
  );
};

export default NWONHeaderLink;
