import { createContext, useContext } from "react";
import { NWONPage } from "../../../config/NWONPage";
import { MainService } from "../../interfaces/MainService";
import { Project } from "../../interfaces/project";
import { SubService } from "../../interfaces/SubService";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";

export interface PageInformationContextData {
  page: NWONPage;
  project?: Project;
  mainService?: MainService;
  subService?: SubService;
  serviceDetailPage?: SubServiceDetailPage;
}

export const PageInformationContext = createContext<PageInformationContextData>(
  {
    page: NWONPage.Home,
  }
);

export const usePageInformationContext = (): PageInformationContextData =>
  useContext(PageInformationContext);
