import { useMemo } from "react";
import { SubService } from "../../interfaces/SubService";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";

type DefaultSubServiceForSubServiceDetailPageMap = Record<
  SubServiceDetailPage,
  SubService
>;

/**
 * Defines the default parent SubService for each SubServiceDetailPage
 * */
const defaultSubServiceForSubServiceDetailPageMap: DefaultSubServiceForSubServiceDetailPageMap =
  {
    [SubServiceDetailPage.AgencyMainz]: SubService.CorporateWebsites,
    [SubServiceDetailPage.CorporateWebsite]: SubService.CorporateWebsites,
    [SubServiceDetailPage.Backend]: SubService.Backend,
    [SubServiceDetailPage.BackendPython]: SubService.Backend,
    [SubServiceDetailPage.BackendNodeJs]: SubService.Backend,
    [SubServiceDetailPage.Deployment]: SubService.Deployment,
    [SubServiceDetailPage.Docker]: SubService.Docker,
    [SubServiceDetailPage.Shop]: SubService.OnlineShops,
    [SubServiceDetailPage.Shopify]: SubService.OnlineShops,
    [SubServiceDetailPage.Frontend]: SubService.Frontend,
  };

/**
 * Get a map that defines the default parent SubService for each SubServiceDetailPage
 * */
export const useDefaultSubServiceForSubServiceDetailPageMap =
  (): DefaultSubServiceForSubServiceDetailPageMap =>
    useMemo(() => defaultSubServiceForSubServiceDetailPageMap, []);
