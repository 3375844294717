import { HtmlTag } from "../../interfaces/variousInterfaces";

export const allHeadlineTags: HtmlTag[] = ["h1", "h2", "h3", "h4", "h5", "h6"];

/**
 * A selector matching all headlines below the current element ("& h1, & h2, …")
 */
export const allNestedHeadlinesSelector = `${allHeadlineTags
  .map((hx) => `& ${hx}`)
  .join(", ")}`;
