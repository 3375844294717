import { SpringConfig } from "@react-spring/web";
import { makeStyles } from "tss-react/mui";
import { headerHeight } from "../../../helpers/clientSide/constants";
import { defaultTransition } from "../../../helpers/styling/defaultTransition";

export const scrollSpringConfig: SpringConfig = {
  tension: 183,
  friction: 32,
  precision: 0.005,
  clamp: true,
};

export const useNWONHeaderStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  appBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "translateY(-1px)",
  },
  appBarRoot: {
    height: headerHeight,
    ...defaultTransition(
      theme,
      ["transform", "opacity", "box-shadow", "background-color"],
      "complex"
    ),
    backgroundColor: "transparent",
    "&.MuiPaper-elevation20": {
      backgroundColor: theme.palette.background.default,
    },
  },
  logoImage: {
    height: headerHeight * 0.618, // because https://en.wikipedia.org/wiki/Golden_ratio,
    width: theme.spacing(7),
  },
  logoLink: {
    zIndex: 1,
    cursor: "pointer",
    display: "flex",
    width: theme.spacing(9),
  },
}));
