import { Instagram, LinkedIn } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNWONSocialMediaIconsStyles } from "./NWONSocialMediaIconsStyles";

export const NWONSocialMediaIcons = (): JSX.Element => {
  const { classes } = useNWONSocialMediaIconsStyles();

  return (
    <>
      <Button
        className={classes.icon}
        href="https://www.instagram.com/nwon.de"
        target="_blank"
      >
        <Instagram color="secondary" fontSize="large" />
      </Button>

      <Button
        className={classes.icon}
        href="https://www.linkedin.com/company/nwon-software-development/"
        target="_blank"
      >
        <LinkedIn color="secondary" fontSize="large" />
      </Button>
    </>
  );
};
