import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as MediheadContracts } from "../../../public/media/projects/medihead/medihead-contracts.png";
import { default as MediheadDashboard } from "../../../public/media/projects/medihead/medihead-dashboard.png";
import RawSvgFile from "../../../public/media/projects/medihead/medihead-logo.svg";
import { default as MediheadPreview } from "../../../public/media/projects/medihead/medihead-preview.png";
import { default as MediheadSure } from "../../../public/media/projects/medihead/medihead-sure.png";
import { Project, ProjectData } from "../../interfaces/project";
import { DjangoLogo } from "../../__generated__/svgComponents/DjangoLogo";

export const useProjectMedihead = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.medihead">("projects.medihead");
  const germanT = i18n.getFixedT(Language.German, "projects.medihead");
  const englishT = i18n.getFixedT(Language.English, "projects.medihead");

  return useMemo(
    () => ({
      name: Project.Medihead,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: MediheadPreview,
      logo: <RawSvgFile />,
      website: "https://app.medihead.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [MediheadPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: MediheadDashboard,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: MediheadContracts,
          },
          {
            title: t("steps.3.title"),
            description: t("steps.3.description"),
            image: MediheadSure,
          },
          {
            title: t("steps.4.title"),
            description: t("steps.4.description"),
            image: <DjangoLogo />,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
