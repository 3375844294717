import { ButtonBase, Typography } from "@mui/material";
import Link from "next/link";
import { UrlParameter } from "../../../../../config/GoogleAdConfiguration";
import { NWONPage } from "../../../../../config/NWONPage";
import { useServiceDetailMap } from "../../../../data/sub-service/useServiceDetailMap";
import { useGetUrl } from "../../../../hooks/urls/useGetUrl";
import { useRouterActiveLanguage } from "../../../../hooks/useRouterActiveLanguage";
import { SubServiceDetailPage } from "../../../../interfaces/SubServiceDetailPage";

export const NWONFooterServiceList = (): JSX.Element => {
  const serviceDetailMap = useServiceDetailMap();
  const getUrl = useGetUrl();
  const locale = useRouterActiveLanguage();

  return (
    <>
      {[
        SubServiceDetailPage.CorporateWebsite,
        SubServiceDetailPage.AgencyMainz,
        SubServiceDetailPage.Frontend,
        SubServiceDetailPage.Shop,
        SubServiceDetailPage.Shopify,
        SubServiceDetailPage.BackendPython,
        SubServiceDetailPage.BackendNodeJs,
        SubServiceDetailPage.Docker,
        SubServiceDetailPage.Deployment,
      ].map((detailPage) => {
        const url = getUrl({
          page: NWONPage.Services,
          serviceDetailPage: detailPage,
          urlParameters: UrlParameter.CtaStyle,
        });

        return (
          <Link href={url} locale={locale} passHref={true} key={detailPage}>
            <ButtonBase>
              <Typography variant="body2" color="secondary">
                {serviceDetailMap[detailPage].title}
              </Typography>
            </ButtonBase>
          </Link>
        );
      })}
    </>
  );
};
