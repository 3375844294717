import { useRouter } from "next/router";

/**
 * Get some specific url parameters
 */
export const useGetUrlParameters = (parameters: string | string[]): string => {
  const router = useRouter();
  const urlParameters = router.query;
  let urlParametersString = "";
  let count = 0;

  Object.entries(urlParameters).forEach(([key, value]) => {
    if (typeof parameters === "string") {
      if (key === parameters) {
        const symbol = "?";
        const optionalValue = value === "" ? "" : `=${value as string}`;
        urlParametersString += symbol + key + optionalValue;
      }
    } else {
      parameters.forEach((parameter) => {
        if (key === parameter) {
          const symbol = count === 0 ? "?" : "&";
          const optionalValue = value === "" ? "" : `=${value as string}`;
          urlParametersString += symbol + key + optionalValue;
          count++;
        }
      });
    }
  });

  return urlParametersString;
};
