import { useMemo } from "react";
import { MainService } from "../../interfaces/MainService";
import { MainServiceData } from "../../interfaces/MainServiceData";
import { useServiceItConsulting } from "./itConsulting";
import { useServiceSoftwareDevelopment } from "./softwareDevelopment";
import { useServiceWebsites } from "./websites";

export const useMainServiceMap = (): Record<MainService, MainServiceData> => {
  const software = useServiceSoftwareDevelopment();
  const websites = useServiceWebsites();
  const itConsulting = useServiceItConsulting();

  return useMemo(
    () => ({
      [MainService.SoftwareDevelopment]: software,
      [MainService.Websites]: websites,
      [MainService.ItConsulting]: itConsulting,
    }),
    [itConsulting, software, websites]
  );
};
