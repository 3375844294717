import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { useMainServiceMap } from "../../data/services/useMainServiceMap";
import { useServiceDetailMap } from "../../data/sub-service/useServiceDetailMap";
import { useSubServicesMap } from "../../data/sub-service/useSubServices";
import { MainService } from "../../interfaces/MainService";
import { SubService } from "../../interfaces/SubService";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";
import { useDefaultDetailPageForSubServiceMap } from "../serviceMappings/useDefaultDetailPageForSubServiceMap";
import { useLanguageUrlComponent } from "./useLanguageUrlComponent";

export const useUrlForServicePage = (
  serviceName?: MainService,
  subServiceName?: SubService,
  serviceDetailPage?: SubServiceDetailPage
): string =>
  useUrlForServicePageCb()(serviceName, subServiceName, serviceDetailPage);

export const useUrlForServicePageCb = (): ((
  serviceName?: MainService,
  subServiceName?: SubService,
  serviceDetailPage?: SubServiceDetailPage
) => string) => {
  const mainServiceMap = useMainServiceMap();
  const subServiceMap = useSubServicesMap();
  const serviceDetailMap = useServiceDetailMap();
  const languageComponent = useLanguageUrlComponent();
  const defaultDetailPageForSubServiceMap =
    useDefaultDetailPageForSubServiceMap();
  const { t } = useTranslation<"pages">("pages");

  return useCallback(
    (
      serviceName?: MainService,
      subServiceName?: SubService,
      serviceDetailPage?: SubServiceDetailPage
    ) => {
      const urlComponents = languageComponent
        ? [languageComponent, t("service.slug")]
        : [t("service.slug")];

      if (serviceName) {
        urlComponents.push(mainServiceMap[serviceName].slug);
      }

      if (subServiceName) {
        urlComponents.push(subServiceMap[subServiceName].slug);
      }

      if (serviceDetailPage) {
        const slug = serviceDetailMap[serviceDetailPage].slug;

        if (
          slug &&
          subServiceName &&
          defaultDetailPageForSubServiceMap[subServiceName] !==
            serviceDetailPage
        ) {
          urlComponents.push(slug);
        }
      }

      return `/${urlComponents.join("/")}`;
    },
    [
      defaultDetailPageForSubServiceMap,
      languageComponent,
      mainServiceMap,
      serviceDetailMap,
      subServiceMap,
      t,
    ]
  );
};
