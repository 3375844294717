import { useEffect, useRef } from "react";

export const sidebar = {
  open: {
    clipPath: "circle(200% at calc(100% - 3.5rem) 2.3rem)",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    clipPath: "circle(0px at calc(100% - 3.5rem) 2.3rem)",
    transition: {
      type: "spring",
      delay: 0.5,
      stiffness: 400,
      damping: 40,
    },
  },
};

export const navbar = {
  open: {
    width: "100%",
  },
  closed: {
    width: "100%",
  },
};

export const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    display: "initial",
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "afterChildren",
    },
    display: "none",
  },
};

export const itemVariant = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDimensions = (
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current) {
      dimensions.current.width = ref.current.offsetWidth;
      dimensions.current.height = ref.current.offsetHeight;
    }
  }, [ref]);

  return dimensions.current;
};
