import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import * as CorporateWebsitesLottie from "../../../public/lottie-jsons/corporate-websites-and-ecommerce.json";
import BnBLogo from "../../../public/media/clientlogos/01_bnb_hotels_no_margins.png";
import VRMLogo from "../../../public/media/clientlogos/03_vrm_no_margins.png";
import ZdfDigitalLogo from "../../../public/media/clientlogos/04_zdf_digital_no_margins.png";
import KSVPreview from "../../../public/media/projects/ksv/ksv-preview.png";
import ResolvioScreenshot from "../../../public/media/projects/resolvio/resolvio-preview.png";
import RheinelementPreview from "../../../public/media/projects/rheinelement/rheinelement-preview.png";
import { useUrlForProjectPage } from "../../hooks/urls/useUrlForProjectPage";
import { MainService } from "../../interfaces/MainService";
import { MainServiceData } from "../../interfaces/MainServiceData";
import { Project } from "../../interfaces/project";
import { useProjectMapping } from "../projects/useAllProjects";
import { useTeamMemberFelix } from "../team/teamMembers/felix";

export const useServiceWebsites = (): MainServiceData => {
  const { t, i18n } = useTranslation<"service">("service");
  const germanT = i18n.getFixedT(Language.German, "service");
  const englishT = i18n.getFixedT(Language.English, "service");
  const getUrl = useUrlForProjectPage();
  const felix = useTeamMemberFelix();
  const projectMapping = useProjectMapping();

  return useMemo(
    () => ({
      lottieAnimationJson: CorporateWebsitesLottie,
      title: t("websites.title"),
      text: t("websites.text"),
      slug: t("websites.slug"),
      slugs: {
        [Language.German]: germanT("websites.slug"),
        [Language.English]: englishT("websites.slug"),
      },
      contactPersonHeader: t("websites.contactPerson.title"),
      contactPersonSubTitle: t("websites.contactPerson.hint"),
      relatedProjectsHeader: t("websites.relatedProjectsHeader"),
      subServiceHeader: t("websites.subServiceHeader"),
      heroSlides: [
        {
          image: RheinelementPreview,
          link: getUrl(Project.Rheinelement),
          title: projectMapping[Project.Rheinelement].title,
        },
        {
          image: KSVPreview,
          link: getUrl(Project.Ksv),
          title: projectMapping[Project.Ksv].title,
        },
        {
          image: ResolvioScreenshot,
          link: getUrl(Project.Resolvio),
          title: projectMapping[Project.Resolvio].title,
        },
      ],
      heroLogos: [ZdfDigitalLogo, BnBLogo, VRMLogo],
      contactTeamMember: felix,
      service: MainService.Websites,
      metaInformation: {
        title: t("websites.meta.title"),
        description: t("websites.meta.description"),
      },
    }),
    [englishT, felix, germanT, getUrl, projectMapping, t]
  );
};
