import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as ZDFDigitalLogo } from "../../../public/media/clientlogos/04_zdf_digital.png";
import { default as DeineInselChat } from "../../../public/media/projects/deine-insel/deine-insel-chat.png";
import { default as DeineInselResult } from "../../../public/media/projects/deine-insel/deine-insel-result.png";
import { default as DeineInselStart } from "../../../public/media/projects/deine-insel/deine-insel-start.png";
import { default as DeineInselStatistics } from "../../../public/media/projects/deine-insel/deine-insel-statistics.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectDeineInsel = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.deineInsel">(
    "projects.deineInsel"
  );
  const germanT = i18n.getFixedT(Language.German, "projects.deineInsel");
  const englishT = i18n.getFixedT(Language.English, "projects.deineInsel");

  return useMemo(
    () => ({
      name: Project.DeineInsel,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: DeineInselStart,
      logo: ZDFDigitalLogo,
      website: "https://deine-insel.zdf.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [DeineInselStart, DeineInselChat, DeineInselResult],
        steps: [
          {
            title: t("steps.step1.title"),
            description: t("steps.step1.description"),
            image: DeineInselStart,
          },
          {
            title: t("steps.step2.title"),
            description: t("steps.step2.description"),
            image: DeineInselChat,
          },
          {
            title: t("steps.step3.title"),
            description: t("steps.step3.description"),
            image: DeineInselStatistics,
          },
        ],
      },

      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
