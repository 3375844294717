import { useProjectMapping } from "../data/projects/useAllProjects";
import { useMainServiceMap } from "../data/services/useMainServiceMap";
import { useServiceDetailMap } from "../data/sub-service/useServiceDetailMap";
import { usePageInformationContext } from "../state/pageInformationContext/PageInformationContext";
import { usePageInformationMap } from "./usePagePathMap";

export const useMetaDescription = (): string => {
  const pageInformation = usePageInformationContext();
  const { mainService, project, page, serviceDetailPage } = pageInformation;

  const serviceDetailPageMap = useServiceDetailMap();
  const mainServiceMap = useMainServiceMap();
  const projectMap = useProjectMapping();
  const pageData = usePageInformationMap()[page];

  if (project) {
    return projectMap[project].metaInformation.description;
  }

  if (serviceDetailPage && serviceDetailPageMap[serviceDetailPage]) {
    return (
      serviceDetailPageMap[serviceDetailPage].metaInformation.description || ""
    );
  }

  if (mainService) {
    return mainServiceMap[mainService].metaInformation.description;
  }

  return pageData.metaInformation.description;
};
