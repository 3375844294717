export enum SubService {
  Api = "API",
  Backend = "Backend",
  CookieConsent = "CookieConsent",
  CorporateWebsites = "CorporateWebsites",
  Deployment = "Deployment",
  DeploymentStrategies = "DeploymentStrategies",
  DeveloperExperience = "DeveloperExperience",
  Docker = "Docker",
  EventWebsites = "EventWebsites",
  JamStack = "JamStack",
  MatchingPlatform = "MatchingPlatform",
  OnlineShops = "OnlineShops",
  ProcessOptimization = "ProcessOptimization",
  Frontend = "Frontend",
  Saas = "Saas",
  SeoOptimization = "SeoOptimization",
  SoftwareArchitecture = "SoftwareArchitecture",
  UserTracking = "UserTracking",
  WordPress = "WordPres",
}
