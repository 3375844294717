import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { HtmlFromLangFileProps } from "./HtmlFromLangFileInterfaces";

/**
 * A quick way to use an html string from the language file.
 *
 * Will be wrapped inside a span element.
 */
const HtmlFromLangFile = (props: HtmlFromLangFileProps): JSX.Element => {
  const replaced = replaceHtmlWithFunctionComponent(
    props.contentContainingHtml
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{replaced}</>;
};

export default HtmlFromLangFile;
