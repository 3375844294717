import { useScrollTrigger } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { scrollTriggerConstant } from "../../../../helpers/clientSide/constants";
import {
  navbar,
  sidebar,
  useDimensions,
} from "../../../../helpers/clientSide/mobileHeaderConfig";
import { useInitializedMediaQuery } from "../../../../hooks/useInitializedMediaQuery";
import theme, { mobileMenuBreakpoint } from "../../../../layouts/theme";
import { useNWONHeaderMobileStyles } from "./NWONHeaderMobileStyles";
import { NWONMobileNavigation } from "./NWONMobileNavigation";

const NWONHeaderMobile = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useNWONHeaderMobileStyles();
  const [pathColor, setPathColor] = useState("hsl(0, 0%, 18%)");
  const containerRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { isMatching: isMobile } = useInitializedMediaQuery(
    theme.breakpoints.down(mobileMenuBreakpoint)
  );

  const isPageScrolled = useScrollTrigger(scrollTriggerConstant(isMobile));

  const { height } = useDimensions(containerRef);

  useEffect(() => {
    if (isOpen || isPageScrolled) {
      setPathColor("hsl(0, 0%, 18%)");
    } else {
      setPathColor("hsl(0, 0%, 100%)");
    }
  }, [isOpen, isPageScrolled]);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={classes.nav}
      variants={navbar}
    >
      <motion.div className={classes.background} variants={sidebar} />

      <NWONMobileNavigation toggleOpen={toggleOpen} />

      <button onClick={() => toggleOpen()} className={classes.toggleButton}>
        <svg width="23" height="23" viewBox="0 0 23 23">
          <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke={pathColor}
            strokeLinecap="round"
            variants={{
              closed: { d: "M 2 2.5 L 20 2.5" },
              open: { d: "M 3 16.5 L 17 2.5" },
            }}
          />

          <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke={pathColor}
            strokeLinecap="round"
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />

          <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke={pathColor}
            strokeLinecap="round"
            variants={{
              closed: { d: "M 2 16.346 L 20 16.346" },
              open: { d: "M 3 2.5 L 17 16.346" },
            }}
          />
        </svg>
      </button>
    </motion.nav>
  );
};

export default NWONHeaderMobile;
