import {
  motionValue,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { SpringOptions } from "popmotion";
import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import nwon from "../../../../public/media/nwon_icon.jpg";
import carina from "../../../../public/media/team/carina.jpg";
import christopher from "../../../../public/media/team/christopher.jpg";
import felix from "../../../../public/media/team/felix.jpg";
import plant01 from "../../../../public/media/team/plants/sample-plant01.jpg";
import reik from "../../../../public/media/team/reik.jpg";
import stefan from "../../../../public/media/team/stefan.jpg";
import { NWONBubbleImageProps } from "../../01-atoms/NWONBubbleImage/NWONBubbleImageInterfaces";
import {
  BubblePageSection,
  NWONFloatingBubblesViewModelProps,
  ParallaxEffectDirection,
} from "./NWONFloatingBubblesInterfaces";

/**
 * The team member images should roughly only move inside of their container when implementing a parallax effect
 * and not across the whole page, so we primarily use the range between `-offsetToTopOfContainer` and `+offsetToBottomOfContainer`
 *
 * Negating the values reverses the direction of the effect.
 *
 * @param direction of the elements
 * @param offsetToTopOfContainer the offset of the elements to the top of their container
 * @param offsetToBottomOfContainer the offset of the elements to the bottom of their container
 * @returns the effect range
 */
const getParallaxEffectRange = (
  direction: ParallaxEffectDirection,
  offsetToTopOfContainer: number,
  offsetToBottomOfContainer: number
): number[] => {
  switch (direction) {
    case ParallaxEffectDirection.TopToBottom:
      return [-offsetToTopOfContainer, offsetToBottomOfContainer];
    case ParallaxEffectDirection.BottomToTop:
      return [offsetToTopOfContainer, -offsetToBottomOfContainer];
  }
};

/*
 * Play around with these settings to control the animation's look and feel
 */
const springYCarina: SpringOptions = {
  damping: 10,
  stiffness: 50,
  mass: 1,
};
const springYChristopher: SpringOptions = {
  damping: 17,
  stiffness: 50,
  mass: 1.4,
};
const springYStefan: SpringOptions = {
  damping: 12,
  stiffness: 50,
  mass: 1.4,
};
const springYNWON: SpringOptions = {
  damping: 9,
  stiffness: 50,
  mass: 1.4,
};
const springYReik: SpringOptions = {
  damping: 8,
  stiffness: 50,
  mass: 1.4,
};
const springYFelix: SpringOptions = {
  damping: 13,
  stiffness: 50,
  mass: 1.4,
};
const springYPlant01: SpringOptions = {
  damping: 15,
  stiffness: 50,
  mass: 1.4,
};

const useCustomizedSpring = (
  props: NWONFloatingBubblesViewModelProps,
  config: SpringOptions | undefined
) => {
  const { offsetTopOfPageRefCurrent, measuresSurroundingBox, direction } =
    props;

  const { height: browserHeight } = useWindowSize();
  const { scrollY } = useViewportScroll();

  const [newScrollYRerender, setNewScrollYRerender] = useState(scrollY.get());

  // Subscribing to an onChange handler so react triggers a re-render.
  useEffect(() => {
    scrollY.onChange((newScrollY) => setNewScrollYRerender(newScrollY));
  }, [scrollY]); // Make sure to re-subscribe when scrollY changes

  const staticOffsetTop = offsetTopOfPageRefCurrent?.offsetTop || 0;
  const staticOffsetBottom =
    (offsetTopOfPageRefCurrent?.offsetTop || 0) + measuresSurroundingBox.height;

  const bublesOffsetToTopAndBottomOfContainer =
    measuresSurroundingBox.height / 2;

  const yPosition = useTransform(
    motionValue(newScrollYRerender),
    [staticOffsetTop - browserHeight, staticOffsetBottom],
    getParallaxEffectRange(
      direction || ParallaxEffectDirection.TopToBottom,
      bublesOffsetToTopAndBottomOfContainer,
      bublesOffsetToTopAndBottomOfContainer
    )
  );

  return useSpring(yPosition, config);
};

export const useNWONFloatingBubbles = (
  props: NWONFloatingBubblesViewModelProps
): NWONBubbleImageProps[] => {
  const { classes } = props;

  const stefanBubble = {
    image: stefan,
    wrapperClassName: classes.bubbleStefan,
    tooltip: "Stefan",
    yPosition: useCustomizedSpring(props, springYStefan),
  };

  const nwonBubble = {
    image: nwon,
    wrapperClassName: classes.bubbleNWON,
    tooltip: "NWON",
    yPosition: useCustomizedSpring(props, springYNWON),
    isHighlighted: true,
  };

  const reikBubble = {
    image: reik,
    wrapperClassName: classes.bubbleReik,
    tooltip: "Reik",
    yPosition: useCustomizedSpring(props, springYReik),
  };

  const felixBubble = {
    image: felix,
    wrapperClassName: classes.bubbleFelix,
    tooltip: "Felix",
    yPosition: useCustomizedSpring(props, springYFelix),
  };

  const plantBubble = {
    image: plant01,
    wrapperClassName:
      props.pageSection === BubblePageSection.TeamSection
        ? props.classes.bubblePlant01
        : "",
    yPosition: useCustomizedSpring(props, springYPlant01),
  };

  const carinaBubble = {
    image: carina,
    wrapperClassName:
      props.pageSection === BubblePageSection.TeamSection
        ? props.classes.bubbleCarina
        : "",
    tooltip: "Carina",
    yPosition: useCustomizedSpring(props, springYCarina),
  };

  const christopherBubble = {
    image: christopher,
    wrapperClassName:
      props.pageSection === BubblePageSection.TeamSection
        ? props.classes.bubbleChristopher
        : "",
    tooltip: "Christopher",
    yPosition: useCustomizedSpring(props, springYChristopher),
  };

  let bubbles: NWONBubbleImageProps[] = [
    stefanBubble,
    nwonBubble,
    reikBubble,
    felixBubble,
  ];

  if (props.pageSection === BubblePageSection.TeamSection) {
    bubbles = [...bubbles, carinaBubble, christopherBubble, plantBubble];
  }

  if (
    [BubblePageSection.TeamSection, BubblePageSection.ContactSection].includes(
      props.pageSection
    )
  ) {
    return bubbles;
  } else {
    return [];
  }
};
