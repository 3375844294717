import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { useProjectMapping } from "../../data/projects/useAllProjects";
import { Project } from "../../interfaces/project";
import { useLanguageUrlComponent } from "./useLanguageUrlComponent";

export const useUrlForProjectPage = (): ((projectName: Project) => string) => {
  const { t } = useTranslation<"pages">("pages");
  const languageComponent = useLanguageUrlComponent();
  const projectMapping = useProjectMapping();

  return useCallback(
    (projectName: Project) => {
      const urlComponents = languageComponent
        ? [
            languageComponent,
            t("projects.slug"),
            projectMapping[projectName].slug,
          ]
        : [t("projects.slug"), projectMapping[projectName].slug];

      return `/${urlComponents.join("/")}`;
    },
    [projectMapping, languageComponent, t]
  );
};
