export enum SubServiceDetailPage {
  CorporateWebsite = "corporateWebsite",
  AgencyMainz = "agencyMainz",
  Backend = "backend",
  BackendPython = "backendPython",
  BackendNodeJs = "backendNodeJs",
  Deployment = "deployment",
  Docker = "docker",
  Shopify = "shopify",
  Frontend = "Frontend",
  Shop = "shop",
}
