// They be very smol.
/* eslint-disable react/no-multi-comp */
import { createEmoji } from "../../components/01-atoms/Emoji/createEmoji";

export const NoResultsEmoji = createEmoji("🥲", "Fröhliches Gesicht mit Träne");

export const WarningEmoji = createEmoji("⚠️", "Warnung");

export const SuccessEmoji = createEmoji("🎉", "Tada");

export const SettingEmoji = createEmoji("⚙️", "Zahnrad aus Metall");

export const DocumentEmoji = createEmoji("📄", "Dokumentenseite");

export const HearNoEvilEmoji = createEmoji(
  "🙉",
  "Äffchen hält sich die Ohren zu"
);

export const SeeNoEvilEmoji = createEmoji(
  "🙈",
  "Äffchen hält sich die Augen zu"
);

export const MuscleEmoji = createEmoji("💪", "Muskulöser Arm");

export const VictoryEmoji = createEmoji("✌", "Victory Zeichen");

export const RunningWomanEmoji = createEmoji("🏃‍♀️", "Rennende Frau");

export const CheckmarkEmoji = createEmoji("✅", "Bestätigendes Checkmark");

export const ConstructionWorkerEmoji = createEmoji("👷‍♀️", "Bauarbeiterin");

export const FireEmoji = createEmoji("🔥", "Fiah");
