import { makeStyles } from "tss-react/mui";
import { mobileMenuBreakpoint } from "../../../../layouts/theme";

export const useNWONSocialMediaIconsStyles = makeStyles()((theme) => ({
  icon: {
    padding: 0,
    justifyContent: "flex-end",
    minWidth: "10px",
    "& > svg": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    [theme.breakpoints.down(mobileMenuBreakpoint)]: {
      justifyContent: "flex-start",
    },
  },
}));
