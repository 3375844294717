import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import * as SoftwareDevelopmentLottie from "../../../public/lottie-jsons/software-development.json";
import CodeImage from "../../../public/media/services/code.jpeg";
import { MainService } from "../../interfaces/MainService";
import { MainServiceData } from "../../interfaces/MainServiceData";
import { useTeamMemberReik } from "../team/teamMembers/reik";

export const useServiceSoftwareDevelopment = (): MainServiceData => {
  const { t, i18n } = useTranslation<"service">("service");
  const germanT = i18n.getFixedT(Language.German, "service");
  const englishT = i18n.getFixedT(Language.English, "service");
  const reik = useTeamMemberReik();

  return useMemo(
    () => ({
      lottieAnimationJson: SoftwareDevelopmentLottie,
      title: t("softwareDevelopment.title"),
      text: t("softwareDevelopment.text"),
      slug: t("softwareDevelopment.slug"),
      slugs: {
        [Language.German]: germanT("softwareDevelopment.slug"),
        [Language.English]: englishT("softwareDevelopment.slug"),
      },
      contactPersonHeader: t("softwareDevelopment.contactPerson.title"),
      contactPersonSubTitle: t("softwareDevelopment.contactPerson.hint"),
      relatedProjectsHeader: t("softwareDevelopment.relatedProjectsHeader"),
      subServiceHeader: t("softwareDevelopment.subServiceHeader"),
      heroSlides: [{ image: CodeImage }],
      contactTeamMember: reik,
      service: MainService.SoftwareDevelopment,
      metaInformation: {
        title: t("softwareDevelopment.meta.title"),
        description: t("softwareDevelopment.meta.description"),
      },
    }),
    [englishT, germanT, reik, t]
  );
};
