import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as PicwiseAnalysis } from "../../../public/media/projects/picwise/picwise-analysis.png";
import PicwiseLogo from "../../../public/media/projects/picwise/picwise-logo.svg";
import { default as PicwiseScreenshot } from "../../../public/media/projects/picwise/picwise-preview.png";
import { default as PicwiseResult } from "../../../public/media/projects/picwise/picwise-result.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectPicwise = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.picwise">("projects.picwise");
  const germanT = i18n.getFixedT(Language.German, "projects.picwise");
  const englishT = i18n.getFixedT(Language.English, "projects.picwise");

  return useMemo(
    () => ({
      name: Project.Picwise,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: PicwiseScreenshot,
      logo: <PicwiseLogo />,
      website: "https://picwi.se",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [PicwiseScreenshot],
        steps: [
          {
            title: t("steps.step1.title"),
            description: t("steps.step1.description"),
            image: PicwiseAnalysis,
          },
          {
            title: t("steps.step2.title"),
            description: t("steps.step2.description"),
            image: PicwiseResult,
          },
        ],
      },

      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
