import { makeStyles } from "tss-react/mui";

export const useNWONFloatingTeamBubblesStyles = makeStyles()((theme) => ({
  bubbleWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    "& *": {
      overflow: "visible!important",
    },
    [theme.breakpoints.down("lg")]: {
      flex: "1 1 auto",
      height: theme.spacing(20),
      top: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  bubbleCarina: {
    left: "30%",
    top: "0%",
    zIndex: 1,
  },
  bubbleChristopher: {
    left: "60%",
    top: "-10%",
    zIndex: 1,
  },
  bubbleStefan: {
    left: "5%",
    top: "10%",
    zIndex: 1,
  },
  bubbleReik: {
    left: "50%",
    top: "90%",
    zIndex: 1,
  },
  bubbleNWON: {
    left: "40%",
    top: "35%",
    zIndex: 1,
  },
  bubbleFelix: {
    left: "25%",
    top: "70%",
    zIndex: 1,
  },
  bubblePlant01: {
    left: "75%",
    top: "45%",
    zIndex: 0,
  },
  bubblePlant02: {
    left: "35%",
    top: "17%",
    zIndex: 0,
  },
  bubblePlant03: {
    left: "38%",
    top: "80%",
    zIndex: 0,
  },
}));
