import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import RheinelementAboutUs from "../../../public/media/projects/rheinelement/rheinelement-about-us.png";
import RheinelementEmployer from "../../../public/media/projects/rheinelement/rheinelement-employer.png";
import RheinelementLogo from "../../../public/media/projects/rheinelement/rheinelement-logo.png";
import RheinelementOrganic from "../../../public/media/projects/rheinelement/rheinelement-organic.png";
import RheinelementPreview from "../../../public/media/projects/rheinelement/rheinelement-preview.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectRheinelement = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.rheinelement">(
    "projects.rheinelement"
  );
  const germanT = i18n.getFixedT(Language.German, "projects.rheinelement");
  const englishT = i18n.getFixedT(Language.English, "projects.rheinelement");

  return useMemo(
    () => ({
      name: Project.Rheinelement,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: RheinelementPreview,
      logo: RheinelementLogo,
      website: "https://rheinelement.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [RheinelementPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: RheinelementOrganic,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: RheinelementEmployer,
          },
          {
            title: t("steps.3.title"),
            description: t("steps.3.description"),
            image: RheinelementAboutUs,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
