import { useCallback } from "react";
import { UrlParameter } from "../../../config/GoogleAdConfiguration";
import { Language } from "../../../config/LanguageConfiguration";
import { usePageInformationContext } from "../../state/pageInformationContext/PageInformationContext";
import { usePageInformationMap } from "../usePagePathMap";
import { useCurrentHash } from "./useCurrentHash";
import { useGetUrlParameters } from "./useGetUrlParameters";
import { useLanguageUrlComponentCb } from "./useLanguageUrlComponent";
import { useUrlForProjectPage } from "./useUrlForProjectPage";
import { useUrlForServicePageCb } from "./useUrlForServicePage";

const urlWithHash = (url: string, hash?: string): string =>
  (hash ? `/${url}#${hash}` : `/${url}`).replace("//", "/");

export const useGetCurrentUrl = (): ((language?: Language) => string) => {
  const pageInformationMap = usePageInformationMap();
  const getLanguageComponent = useLanguageUrlComponentCb();
  const urlParameters = useGetUrlParameters([
    UrlParameter.Lead,
    UrlParameter.CtaStyle,
  ]);
  const hash = useCurrentHash();

  const pageInformation = usePageInformationContext();

  const servicePageUrl = useUrlForServicePageCb();
  const projectPageUrl = useUrlForProjectPage();

  return useCallback(
    (language?: Language): string => {
      const languageComponent = getLanguageComponent(language);

      if (pageInformation.project) {
        return urlWithHash(
          `${projectPageUrl(pageInformation.project)}${urlParameters}`,
          hash
        );
      }

      if (pageInformation.mainService) {
        return urlWithHash(
          `${servicePageUrl(
            pageInformation.mainService,
            pageInformation.subService,
            pageInformation.serviceDetailPage
          )}${urlParameters}`,
          hash
        );
      }

      const urlComponents = languageComponent
        ? [languageComponent, pageInformationMap[pageInformation.page].slug]
        : [pageInformationMap[pageInformation.page].slug];

      return urlWithHash(`${urlComponents.join("/")}${urlParameters}`, hash);
    },
    [
      getLanguageComponent,
      pageInformation.project,
      pageInformation.mainService,
      pageInformation.page,
      pageInformation.subService,
      pageInformation.serviceDetailPage,
      pageInformationMap,
      urlParameters,
      hash,
      projectPageUrl,
      servicePageUrl,
    ]
  );
};
