import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CSSProperties } from "tss-react/tools/types/CSSObject";
import { mobileMenuBreakpoint } from "../../../layouts/theme";
import { NWONHeaderLinkStylesProps } from "./NWONHeaderLinkInterfaces";

const isActiveLink = (
  theme: Theme,
  { isPageScrolled, isActive }: NWONHeaderLinkStylesProps
): CSSProperties["color"] => {
  if (isPageScrolled) {
    return isActive ? `2px solid ${theme.palette.text.primary}` : "none";
  } else {
    return isActive ? `2px solid ${theme.palette.grey[200]}` : "none";
  }
};

export const useNWONHeaderLinkStyles = makeStyles<NWONHeaderLinkStylesProps>()(
  (theme, props) => ({
    headerLinkTypography: {
      display: "inline-block",
      width: "100%",
      fontWeight: theme.typography.fontWeightBold,
      color: props.isPageScrolled
        ? theme.palette.text.primary
        : theme.palette.grey[200],
      textDecoration: "none",
      borderBottom: isActiveLink(theme, props),
      [theme.breakpoints.down(mobileMenuBreakpoint)]: {
        textAlign: "center",
        display: "block",
        marginLeft: 0,
        padding: theme.spacing(1, 0),
      },
    },
  })
);
