import { makeStyles } from "tss-react/mui";

export const useNWONCTAIconListStyles = makeStyles()((theme) => ({
  wrapper: {},
  listItem: {
    padding: theme.spacing(0.5, 0),
  },
  listItemIcon: {
    minWidth: 40,
    color: theme.palette.common.white,
  },
}));
