import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as KSVLogo } from "../../../public/media/projects/ksv/ksv-logo.png";
import { default as KSVPreview } from "../../../public/media/projects/ksv/ksv-preview.png";
import { default as KSVProjects } from "../../../public/media/projects/ksv/ksv-projects.png";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectKsv = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.ksv">("projects.ksv");
  const germanT = i18n.getFixedT(Language.German, "projects.ksv");
  const englishT = i18n.getFixedT(Language.English, "projects.ksv");

  return useMemo(
    () => ({
      name: Project.Ksv,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: KSVPreview,
      logo: KSVLogo,
      website: "https://ksv-network.de",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [KSVPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: KSVProjects,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
