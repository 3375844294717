import { Typography } from "@mui/material";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import {
  NWONTrackingEvent,
  trackEvent,
} from "../../../00-globals/TruendoGoogleTagManager/trackEvent";

export const NWONContact = (): JSX.Element => {
  const { t } = useTranslation<"common">();

  const handleAdConversion = () => {
    trackEvent(NWONTrackingEvent.AdConversion);
  };

  return (
    <>
      <Link href={`mailto:${t("contactInformation.email")}`}>
        <a onClick={handleAdConversion}>
          <Typography variant="body2" color="secondary">
            {t("contactInformation.email")}
          </Typography>
        </a>
      </Link>

      <Link href={`tel:${t("contactInformation.phone")}`}>
        <a onClick={handleAdConversion}>
          <Typography variant="body2" color="secondary">
            {t("contactInformation.phone")}
          </Typography>
        </a>
      </Link>
    </>
  );
};
