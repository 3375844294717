import { ButtonBase, Typography } from "@mui/material";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { UrlParameter } from "../../../../../config/GoogleAdConfiguration";
import { NWONPage } from "../../../../../config/NWONPage";
import { useGetUrl } from "../../../../hooks/urls/useGetUrl";
import { useRouterActiveLanguage } from "../../../../hooks/useRouterActiveLanguage";

export const NWONPageList = (): JSX.Element => {
  const { t } = useTranslation<"common">();
  const locale = useRouterActiveLanguage();
  const getUrl = useGetUrl();
  const urlImprint = getUrl({
    page: NWONPage.Imprint,
    urlParameters: UrlParameter.CtaStyle,
  });
  const urlPrivacyPolicy = getUrl({
    page: NWONPage.PrivacyPolicy,
    urlParameters: UrlParameter.CtaStyle,
  });

  const showPanel = () => {
    if (typeof window !== "undefined") {
      window.Truendo?.openCookieSettings();
    }
  };

  const pageLinks: { title: string; page: NWONPage }[] = [
    { title: t("menuItems.services"), page: NWONPage.Services },
    { title: t("menuItems.projects"), page: NWONPage.Projects },
    { title: t("menuItems.team"), page: NWONPage.Team },
  ];

  return (
    <>
      {pageLinks.map((link) => {
        const urlLink = getUrl({
          page: link.page,
          urlParameters: UrlParameter.CtaStyle,
        });

        return (
          <Link href={urlLink} locale={locale} passHref={true} key={link.title}>
            <ButtonBase disableRipple={true}>
              <Typography variant="body2" color="secondary">
                {link.title}
              </Typography>
            </ButtonBase>
          </Link>
        );
      })}

      <br />

      <Link href={urlImprint} locale={locale} passHref={true}>
        <ButtonBase disableRipple={true}>
          <Typography variant="body2" color="secondary">
            {t("footer.imprint")}
          </Typography>
        </ButtonBase>
      </Link>

      <Link href={urlPrivacyPolicy} locale={locale} passHref={true}>
        <ButtonBase disableRipple={true}>
          <Typography variant="body2" color="secondary">
            {t("footer.privacyPolicy")}
          </Typography>
        </ButtonBase>
      </Link>

      <ButtonBase disableRipple={true} onClick={showPanel}>
        <Typography variant="body2" color="secondary">
          {t("footer.cookieEinstellungen")}
        </Typography>
      </ButtonBase>
    </>
  );
};
