import Reik from "../../../../public/media/team/reik.jpg";
import { TeamMember } from "../../../interfaces/team";

export const useTeamMemberReik = (): TeamMember => ({
  firstName: "Dr. Reik Benjamin",
  lastName: "Stiebeling",
  position: "Full-Stack Webentwickler und Co-Founder",
  email: "reik@nwon.de",
  description:
    "Ich berate dich gerne zur individuellen Entwicklung von Websites und Webapps. ",
  phoneNumber: "+49 6131 6246 560",
  image: Reik,
});
