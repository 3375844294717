import { PageInformationContext } from "../../state/pageInformationContext/PageInformationContext";
import NWONFooter from "../03-organisms/NWONFooter/NWONFooter";
import NWONHeader from "../03-organisms/NWONHeader/NWONHeader";
import { NWONMetaTags } from "../NWONMetaTags/NWONMetaTags";
import { NWONInitialProps } from "./NWONInitialInterfaces";

export const NWONInitial = (props: NWONInitialProps): JSX.Element => {
  return (
    <PageInformationContext.Provider value={props.pageInformationData}>
      <NWONMetaTags />

      <NWONHeader />

      {props.children}

      <NWONFooter />
    </PageInformationContext.Provider>
  );
};
