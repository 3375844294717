import { useMemo } from "react";
import { MainService } from "../../interfaces/MainService";
import { SubService } from "../../interfaces/SubService";

type DefaultMainServiceForSubServiceMap = Record<SubService, MainService>;

/**
 * Defines the default parent MainService for each SubService
 * */
export const defaultMainServiceForSubServiceMap: DefaultMainServiceForSubServiceMap =
  {
    [SubService.Api]: MainService.SoftwareDevelopment,
    [SubService.Backend]: MainService.SoftwareDevelopment,
    [SubService.CookieConsent]: MainService.SoftwareDevelopment,
    [SubService.CorporateWebsites]: MainService.Websites,
    [SubService.Deployment]: MainService.SoftwareDevelopment,
    [SubService.DeploymentStrategies]: MainService.SoftwareDevelopment,
    [SubService.DeveloperExperience]: MainService.SoftwareDevelopment,
    [SubService.Docker]: MainService.SoftwareDevelopment,
    [SubService.EventWebsites]: MainService.Websites,
    [SubService.JamStack]: MainService.Websites,
    [SubService.MatchingPlatform]: MainService.SoftwareDevelopment,
    [SubService.OnlineShops]: MainService.Websites,
    [SubService.ProcessOptimization]: MainService.ItConsulting,
    [SubService.Frontend]: MainService.SoftwareDevelopment,
    [SubService.Saas]: MainService.ItConsulting,
    [SubService.SeoOptimization]: MainService.ItConsulting,
    [SubService.SoftwareArchitecture]: MainService.ItConsulting,
    [SubService.UserTracking]: MainService.Websites,
    [SubService.WordPress]: MainService.Websites,
  };

/**
 * Get a map that defines the default parent MainService for each SubService
 * */
export const useDefaultMainServiceForSubServiceMap =
  (): DefaultMainServiceForSubServiceMap =>
    useMemo(() => defaultMainServiceForSubServiceMap, []);
