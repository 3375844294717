import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { SubService } from "../../interfaces/SubService";
import { SubServiceData } from "../../interfaces/SubServiceData";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";

export const useSubServicesMap = (): Record<SubService, SubServiceData> => {
  const { i18n, t } = useTranslation<"subService">("subService");
  const germanT = i18n.getFixedT(Language.German, "subService");
  const englishT = i18n.getFixedT(Language.English, "subService");

  return useMemo(
    () => ({
      [SubService.Saas]: {
        subService: SubService.Saas,
        slug: t("saas.slug"),
        slugs: {
          [Language.German]: germanT("saas.slug"),
          [Language.English]: englishT("saas.slug"),
        },
        title: t("saas.title"),
        description: t("saas.description"),
      },
      [SubService.SoftwareArchitecture]: {
        subService: SubService.SoftwareArchitecture,
        slug: t("softwareArchitecture.slug"),
        slugs: {
          [Language.German]: germanT("softwareArchitecture.slug"),
          [Language.English]: englishT("softwareArchitecture.slug"),
        },
        title: t("softwareArchitecture.title"),
        description: t("softwareArchitecture.description"),
      },
      [SubService.DeploymentStrategies]: {
        subService: SubService.DeploymentStrategies,
        slug: t("deployment.slug"),
        slugs: {
          [Language.German]: germanT("deployment.slug"),
          [Language.English]: englishT("deployment.slug"),
        },
        title: t("deployment.title"),
        description: t("deployment.description"),
      },
      [SubService.SeoOptimization]: {
        subService: SubService.SeoOptimization,
        slug: t("seo.slug"),
        slugs: {
          [Language.German]: germanT("seo.slug"),
          [Language.English]: englishT("seo.slug"),
        },
        title: t("seo.title"),
        description: t("seo.description"),
      },
      [SubService.MatchingPlatform]: {
        subService: SubService.MatchingPlatform,
        slug: t("matchingPlatform.slug"),
        slugs: {
          [Language.German]: germanT("matchingPlatform.slug"),
          [Language.English]: englishT("matchingPlatform.slug"),
        },
        title: t("matchingPlatform.title"),
        description: t("matchingPlatform.description"),
      },
      [SubService.ProcessOptimization]: {
        subService: SubService.ProcessOptimization,
        slug: t("processOptimization.slug"),
        slugs: {
          [Language.German]: germanT("processOptimization.slug"),
          [Language.English]: englishT("processOptimization.slug"),
        },
        title: t("processOptimization.title"),
        description: t("processOptimization.description"),
      },
      [SubService.Frontend]: {
        subService: SubService.Frontend,
        slug: t("frontend.slug"),
        slugs: {
          [Language.German]: germanT("frontend.slug"),
          [Language.English]: englishT("frontend.slug"),
        },
        title: t("frontend.title"),
        description: t("frontend.description"),
      },
      [SubService.Docker]: {
        subService: SubService.Docker,
        slug: t("docker.slug"),
        slugs: {
          [Language.German]: germanT("docker.slug"),
          [Language.English]: englishT("docker.slug"),
        },
        title: t("docker.title"),
        description: t("docker.description"),
        detailPage: [SubServiceDetailPage.Docker],
      },
      [SubService.Deployment]: {
        subService: SubService.Deployment,
        slug: t("deployment.slug"),
        slugs: {
          [Language.German]: germanT("deployment.slug"),
          [Language.English]: englishT("deployment.slug"),
        },
        title: t("deployment.title"),
        description: t("deployment.description"),
        detailPage: [SubServiceDetailPage.Deployment],
      },
      [SubService.Api]: {
        subService: SubService.Api,
        slug: t("api.slug"),
        slugs: {
          [Language.German]: germanT("api.slug"),
          [Language.English]: englishT("api.slug"),
        },
        title: t("api.title"),
        description: t("api.description"),
      },
      [SubService.Backend]: {
        subService: SubService.Backend,
        slug: t("backend.slug"),
        slugs: {
          [Language.German]: germanT("backend.slug"),
          [Language.English]: englishT("backend.slug"),
        },
        title: t("backend.title"),
        description: t("backend.description"),
        detailPage: [
          SubServiceDetailPage.Backend,
          SubServiceDetailPage.BackendNodeJs,
          SubServiceDetailPage.BackendPython,
        ],
      },
      [SubService.CorporateWebsites]: {
        subService: SubService.CorporateWebsites,
        slug: t("corporateWebsite.slug"),
        slugs: {
          [Language.German]: germanT("corporateWebsite.slug"),
          [Language.English]: englishT("corporateWebsite.slug"),
        },
        title: t("corporateWebsite.title"),
        description: t("corporateWebsite.description"),
        detailPage: [
          SubServiceDetailPage.CorporateWebsite,
          SubServiceDetailPage.AgencyMainz,
        ],
      },
      [SubService.EventWebsites]: {
        subService: SubService.EventWebsites,
        slug: t("eventWebsite.slug"),
        slugs: {
          [Language.German]: germanT("eventWebsite.slug"),
          [Language.English]: englishT("eventWebsite.slug"),
        },
        title: t("eventWebsite.title"),
        description: t("eventWebsite.description"),
      },
      [SubService.OnlineShops]: {
        subService: SubService.OnlineShops,
        slug: t("onlineShop.slug"),
        slugs: {
          [Language.German]: germanT("onlineShop.slug"),
          [Language.English]: englishT("onlineShop.slug"),
        },
        title: t("onlineShop.title"),
        description: t("onlineShop.description"),
      },
      [SubService.WordPress]: {
        subService: SubService.WordPress,
        slug: t("wordPress.slug"),
        slugs: {
          [Language.German]: germanT("wordPress.slug"),
          [Language.English]: englishT("wordPress.slug"),
        },
        title: t("wordPress.title"),
        description: t("wordPress.description"),
      },
      [SubService.JamStack]: {
        subService: SubService.JamStack,
        slug: t("jamstack.slug"),
        slugs: {
          [Language.German]: germanT("jamstack.slug"),
          [Language.English]: englishT("jamstack.slug"),
        },
        title: t("jamstack.title"),
        description: t("jamstack.description"),
      },
      [SubService.SeoOptimization]: {
        subService: SubService.SeoOptimization,
        slug: t("seo.slug"),
        slugs: {
          [Language.German]: germanT("seo.slug"),
          [Language.English]: englishT("seo.slug"),
        },
        title: t("seo.title"),
        description: t("seo.description"),
      },
      [SubService.UserTracking]: {
        subService: SubService.UserTracking,
        slug: t("userTracking.slug"),
        slugs: {
          [Language.German]: germanT("userTracking.slug"),
          [Language.English]: englishT("userTracking.slug"),
        },
        title: t("userTracking.title"),
        description: t("userTracking.description"),
      },
      [SubService.CookieConsent]: {
        subService: SubService.CookieConsent,
        slug: t("cookieConsent.slug"),
        slugs: {
          [Language.German]: germanT("cookieConsent.slug"),
          [Language.English]: englishT("cookieConsent.slug"),
        },
        title: t("cookieConsent.title"),
        description: t("cookieConsent.description"),
      },
      [SubService.DeveloperExperience]: {
        subService: SubService.DeveloperExperience,
        slug: t("developerExperience.slug"),
        slugs: {
          [Language.German]: germanT("developerExperience.slug"),
          [Language.English]: englishT("developerExperience.slug"),
        },
        title: t("developerExperience.title"),
        description: t("developerExperience.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
