import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, ButtonGroup, Tooltip, Typography } from "@mui/material";
import { useBooleanState } from "@nvon/react-toolbox";
import { useCopyToClipboard } from "react-use";
import { CheckmarkEmoji } from "../../../helpers/clientSide/emojis";
import { NWONCopyToClipboardButtonProps } from "./NWONCopyToClipboardButtonInterfaces";
import { useNWONCopyToClipboardButtonStyles } from "./NWONCopyToClipboardButtonStyles";

export const NWONCopyToClipboardButton = (
  props: NWONCopyToClipboardButtonProps
): JSX.Element => {
  const { classes, cx } = useNWONCopyToClipboardButtonStyles();
  const [, copyToClipboard] = useCopyToClipboard();

  const additionalOnCopy = () => {
    if (props.copyOnClick) {
      props.copyOnClick();
    }
  };

  const {
    off: setCopiedToClipboardFalse,
    on: setCopiedToClipboardTrue,
    state: isCopiedToClipboard,
  } = useBooleanState(false);

  const copyTooltipText = (
    <Typography variant="body1">{props.copyTooltipText}</Typography>
  );

  const copiedTooltipText = (
    <Typography variant="body1">
      <CheckmarkEmoji />

      {` ${props.copiedTooltipText}`}
    </Typography>
  );

  return (
    <ButtonGroup
      color={props.buttonGroupColor}
      variant={props.buttonGroupVariant}
    >
      <Button
        aria-label={props.textToCopy}
        onClick={props.buttonOnClick}
        {...props.leftButtonProps}
      />

      <Tooltip
        disableFocusListener={true}
        title={isCopiedToClipboard ? copiedTooltipText : copyTooltipText}
        arrow={true}
        placement="top"
        PopperProps={{
          className: cx(
            isCopiedToClipboard
              ? classes.popperTooltipCopied
              : classes.popperTooltipCopy
          ),
        }}
      >
        <Button
          aria-label="copy to clipboard"
          className={classes.copyClipboardButton}
          onClick={() => {
            copyToClipboard(props.textToCopy);
            setCopiedToClipboardTrue();
            setTimeout(() => {
              setCopiedToClipboardFalse();
            }, 3000);
            additionalOnCopy();
          }}
        >
          <ContentCopyIcon className={classes.copyClipBoardIcon} />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
