import Felix from "../../../../public/media/team/felix.jpg";
import { TeamMember } from "../../../interfaces/team";

export const useTeamMemberFelix = (): TeamMember => ({
  firstName: "Felix",
  lastName: "Buchmüller",
  position: "Co-Founder, Projektmanager und SEO-Experte.",
  email: "felix@nwon.de",
  description:
    "Ich berate dich gerne zum Thema Website-Architektur, Hosting, SEO, API-Entwicklung und Co. Und wer weiß.. vielleicht arbeiten wir ja schon bald gemeinsame an deinem neuen Projekt! ",
  phoneNumber: "+49 157 7744 2890",
  image: Felix,
});
