import { Element as HtmlElement, Node } from "html-react-parser";
import { HtmlTag } from "../../../../interfaces/variousInterfaces";
import { allHeadlineTags } from "../../../styling/allHeadlines";
import { nodeIsTag } from "./nodeIsTag";

export const nodeIsHeadline = (child: Node): child is HtmlElement => {
  if (!nodeIsTag(child)) {
    return false;
  }

  return allHeadlineTags.includes(child.name as HtmlTag);
};
