import { Box } from "@mui/material";
import NWONBubbleImage from "../../01-atoms/NWONBubbleImage/NWONBubbleImage";
import { NWONFloatingBubblesProps } from "./NWONFloatingBubblesInterfaces";
import { useNWONFloatingBubblesViewModel } from "./NWONFloatingBubblesViewModel";

const NWONFloatingBubbles = (
  props: NWONFloatingBubblesProps
): JSX.Element | null => {
  const viewModel = useNWONFloatingBubblesViewModel(props);

  if (!viewModel) {
    return null;
  }

  return (
    <Box className={viewModel.bubbleWrapperClass}>
      {viewModel.bubbles.map((bubble) => {
        return (
          <NWONBubbleImage
            key={bubble.wrapperClassName}
            image={bubble.image}
            yPosition={bubble.yPosition}
            wrapperClassName={bubble.wrapperClassName}
            isHighlighted={bubble.isHighlighted}
            tooltip={bubble.tooltip}
          />
        );
      })}
    </Box>
  );
};

export default NWONFloatingBubbles;
