import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";

export const useNWONFooterStyles = makeStyles()((theme) => ({
  sectionWrapper: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: important(0),
  },
  contactWrapper: {
    width: "100%",
    marginTop: theme.spacing(-2),
    padding: theme.spacing(0, 0),
  },
  footerContainer: {
    justifyContent: "flex-start",
    margin: theme.spacing(2, 0),
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1, 0),
      justifyContent: "space-between",
    },
    [theme.breakpoints.only("xs")]: {
      rowGap: theme.spacing(2),
    },
  },
  footerWrapper: {
    width: "100%",
  },
  addressColumn: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.not("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  followUsColumn: {
    [theme.breakpoints.only("sm")]: {
      paddingTop: theme.spacing(2),
    },
  },
}));
