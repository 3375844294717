import { useMediaQuery } from "@mui/material";
import { useBooleanState } from "@nvon/react-toolbox";
import { useEffect } from "react";

type UseMediaInput = Parameters<typeof useMediaQuery>;

export const useInitializedMediaQuery = (
  query: UseMediaInput[0],
  options?: UseMediaInput[1]
): {
  isMatching: boolean;
  isInitialized: boolean;
} => {
  const { state: isInitialized, on: setIsInitializedTrue } =
    useBooleanState(false);

  const isMatching = useMediaQuery(query, options);

  useEffect(() => {
    setIsInitializedTrue();
  }, [setIsInitializedTrue]);

  return {
    isInitialized,
    isMatching,
  };
};
