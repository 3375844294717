import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { Language } from "../../../config/LanguageConfiguration";
import { default as EvolvingRegionsClimate } from "../../../public/media/projects/evolving-regions/evolving-regions-climate.png";
import EvolvingRegionsLogo from "../../../public/media/projects/evolving-regions/evolving-regions-logo.svg";
import { default as EvolvingRegionsNl } from "../../../public/media/projects/evolving-regions/evolving-regions-nl.png";
import { default as EvolvingRegionsPreview } from "../../../public/media/projects/evolving-regions/evolving-regions-preview.jpg";
import { Project, ProjectData } from "../../interfaces/project";

export const useProjectEvolvingRegions = (): ProjectData => {
  const { t, i18n } = useTranslation<"projects.evolvingRegions">(
    "projects.evolvingRegions"
  );
  const germanT = i18n.getFixedT(Language.German, "projects.evolvingRegions");
  const englishT = i18n.getFixedT(Language.English, "projects.evolvingRegions");

  return useMemo(
    () => ({
      name: Project.EvolvingRegions,
      title: t("title"),
      subtitle: t("subtitle"),
      description: t("description"),
      previewImage: EvolvingRegionsPreview,
      logo: <EvolvingRegionsLogo />,
      website: "https://evolvingregions.com",
      hashtags: t("hashtags"),
      slug: t("slug"),
      slugs: {
        [Language.German]: germanT("slug"),
        [Language.English]: englishT("slug"),
      },
      details: {
        heroImages: [EvolvingRegionsPreview],
        steps: [
          {
            title: t("steps.1.title"),
            description: t("steps.1.description"),
            image: EvolvingRegionsClimate,
          },
          {
            title: t("steps.2.title"),
            description: t("steps.2.description"),
            image: EvolvingRegionsNl,
          },
        ],
      },
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
    }),
    [englishT, germanT, t]
  );
};
