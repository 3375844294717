import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";
import { headerHeight } from "../../../helpers/clientSide/constants";

// To avoid the overlap of the header and the contact section we need at least scrollMarginTop = headerHeight
const scrollMarginTopBubbles = headerHeight * 2; // Avoid overlap and show bubbles
const scrollMarginTopNoBubbles = headerHeight * 1.3; // Avoid overlap and improve UI when there aren't bubbles on the contact section

export const useNWONContactSectionStyles = makeStyles()((theme) => ({
  wrapperSection: {
    scrollMarginTop: `${scrollMarginTopBubbles}px`,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginBottom: important(theme.spacing(2)),
    padding: theme.spacing(2),
    "& .MuiContainer-root": {
      padding: important(0),
    },
    [theme.breakpoints.down("lg")]: {
      scrollMarginTop: `${scrollMarginTopNoBubbles}px`,
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-root": {
        width: "100%",
      },
      "& .MuiButtonGroup-root": {
        width: "100%",
        "& > a": {
          width: "100%",
        },
        "& > button": {
          width: "fit-content",
        },
      },
    },
  },
  innerWrapperSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  benefitsListWrapper: {
    margin: theme.spacing(2, 0),
  },
  introText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
    },
  },
  buttonsWrapper: {
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
  floatingBubblesWrapper: {
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));
