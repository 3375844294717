import { useCallback } from "react";
import { NWONPage } from "../../../config/NWONPage";
import { useProjectMapping } from "../../data/projects/useAllProjects";
import { useMainServiceMap } from "../../data/services/useMainServiceMap";
import { useServiceDetailMap } from "../../data/sub-service/useServiceDetailMap";
import { useSubServicesMap } from "../../data/sub-service/useSubServices";
import { MainService } from "../../interfaces/MainService";
import { Project } from "../../interfaces/project";
import { SubService } from "../../interfaces/SubService";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";
import { useDefaultDetailPageForSubServiceMap } from "../serviceMappings/useDefaultDetailPageForSubServiceMap";
import { useDefaultMainServiceForSubServiceMap } from "../serviceMappings/useDefaultMainServiceForSubServiceMap";
import { useDefaultSubServiceForSubServiceDetailPageMap } from "../serviceMappings/useDefaultSubServiceForSubServiceDetailPageMap";

import { usePageInformationMap } from "../usePagePathMap";
import { useGetUrlParameters } from "./useGetUrlParameters";
import { useLanguageUrlComponent } from "./useLanguageUrlComponent";

interface GetUrlProps {
  page: NWONPage;
  project?: Project;
  mainService?: MainService;
  subService?: SubService;
  serviceDetailPage?: SubServiceDetailPage;
  hash?: string;
  urlParameters?: string | string[];
}

const getHash = (hash?: string): string => (hash ? "#" + hash : "");

const OptionalUrlParameters = (urlParameters: string | string[]): string => {
  return useGetUrlParameters(urlParameters);
};

export const useGetUrl = (): ((props: GetUrlProps) => string) => {
  const pageData = usePageInformationMap();
  const projectMapping = useProjectMapping();
  const mainServiceMap = useMainServiceMap();
  const subServiceMap = useSubServicesMap();
  const serviceDetailMap = useServiceDetailMap();
  const defaultSubServiceForSubServiceDetailPageMap =
    useDefaultSubServiceForSubServiceDetailPageMap();
  const defaultMainServiceForSubServiceMap =
    useDefaultMainServiceForSubServiceMap();
  const defaultDetailPageForSubServiceMap =
    useDefaultDetailPageForSubServiceMap();

  const languageComponent = useLanguageUrlComponent();

  return useCallback(
    (props: GetUrlProps): string => {
      const { project, page, hash, serviceDetailPage, urlParameters } = props;

      /**
       * Make sure the right services are set for detail page
       */

      const subService =
        serviceDetailPage && !props.subService
          ? defaultSubServiceForSubServiceDetailPageMap[serviceDetailPage]
          : props.subService;

      const mainService =
        subService && !props.mainService
          ? defaultMainServiceForSubServiceMap[subService]
          : props.mainService;

      const urlComponents = languageComponent
        ? [languageComponent, pageData[page].slug]
        : [pageData[page].slug];

      if (project && props.page === NWONPage.Projects) {
        urlComponents.push(projectMapping[project].slug);
      }

      if (mainService && props.page === NWONPage.Services) {
        urlComponents.push(mainServiceMap[mainService].slug);
      }

      if (subService && props.page === NWONPage.Services) {
        urlComponents.push(subServiceMap[subService].slug);
      }

      if (
        serviceDetailPage &&
        props.page === NWONPage.Services &&
        subService &&
        defaultDetailPageForSubServiceMap[subService] !== serviceDetailPage
      ) {
        const slug = serviceDetailMap[serviceDetailPage]?.slug;
        if (slug) {
          urlComponents.push(slug);
        }
      }

      if (urlParameters !== undefined) {
        return `/${urlComponents.join("/")}${OptionalUrlParameters(
          urlParameters
        )}${getHash(hash)}`;
      } else {
        return `/${urlComponents.join("/")}${getHash(hash)}`;
      }
    },
    [
      defaultSubServiceForSubServiceDetailPageMap,
      defaultMainServiceForSubServiceMap,
      languageComponent,
      pageData,
      defaultDetailPageForSubServiceMap,
      projectMapping,
      mainServiceMap,
      subServiceMap,
      serviceDetailMap,
    ]
  );
};
