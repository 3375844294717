import { RocketLaunch, Star } from "@mui/icons-material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TerminalIcon from "@mui/icons-material/Terminal";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ServiceNameSpaces } from "../../i18n/i18n-namespaces";
import { SubServiceDetailPage } from "../../interfaces/SubServiceDetailPage";
import { SubServiceDetailPageData } from "../../interfaces/SubServiceDetailPageData";
import { Tool, useToolMap } from "./useToolMap";

export const useUserDetailTexts = (
  nameSpace: ServiceNameSpaces
): SubServiceDetailPageData => {
  /**
   * Sadly I need to ts-ignore this as I don't know how to fix the
   * Type instantiation is excessively deep and possibly infinite
   * error
   */

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { t, i18n } = useTranslation(nameSpace);

  return useMemo(
    () => ({
      slug: i18n.exists(`${nameSpace}:slug`)
        ? t("slug", { ns: nameSpace })
        : undefined,
      title: t("title"),
      metaInformation: {
        title: t("meta.title"),
        description: t("meta.description"),
      },
      heroTitle: t("heroTitle"),
      heroText: t("heroText"),
      pageTitle: t("pageTitle"),
      relatedProjectTitle: t("relatedProjectTitle"),
      processStepsTitle: t("processStepsTitle"),
      faqTitle: t("faqTitle"),
      toolLogosTitle: t("toolLogosTitle"),
      keyFacts: t("keyFacts"),
      processSteps: [],
      toolLogos: [],
      faq: t("faqs"),
    }),
    [i18n, nameSpace, t]
  );
};

export const useServiceDetailMap = (): Record<
  SubServiceDetailPage,
  SubServiceDetailPageData
> => {
  const toolMap = useToolMap();
  const backend = useUserDetailTexts("serviceDetailPage.backend");
  const backendNodeJs = useUserDetailTexts("serviceDetailPage.backendNodeJs");
  const backendPython = useUserDetailTexts("serviceDetailPage.backendPython");
  const shopify = useUserDetailTexts("serviceDetailPage.shopify");
  const frontend = useUserDetailTexts("serviceDetailPage.frontend");
  const shop = useUserDetailTexts("serviceDetailPage.shop");
  const corporateWebsites = useUserDetailTexts(
    "serviceDetailPage.corporateWebsites"
  );
  const corporateWebsitesMainz = useUserDetailTexts(
    "serviceDetailPage.corporateWebsitesMainz"
  );
  const deployment = useUserDetailTexts("serviceDetailPage.deployment");
  const docker = useUserDetailTexts("serviceDetailPage.docker");

  const { t: tCorporateWebsitesMainz } =
    useTranslation<"serviceDetailPage.corporateWebsitesMainz">(
      "serviceDetailPage.corporateWebsitesMainz"
    );
  const { t: tCorporateWebsites } =
    useTranslation<"serviceDetailPage.corporateWebsites">(
      "serviceDetailPage.corporateWebsites"
    );
  const { t: tBackend } = useTranslation<"serviceDetailPage.backend">(
    "serviceDetailPage.backend"
  );
  const { t: tDocker } = useTranslation<"serviceDetailPage.docker">(
    "serviceDetailPage.docker"
  );
  const { t: tDeployment } = useTranslation<"serviceDetailPage.deployment">(
    "serviceDetailPage.deployment"
  );
  const { t: tShop } = useTranslation<"serviceDetailPage.shop">(
    "serviceDetailPage.shop"
  );
  const { t: tShopify } = useTranslation<"serviceDetailPage.shopify">(
    "serviceDetailPage.shopify"
  );
  const { t: tFrontend } = useTranslation<"serviceDetailPage.frontend">(
    "serviceDetailPage.frontend"
  );

  return useMemo(
    () => ({
      [SubServiceDetailPage.Shopify]: {
        ...shopify,
        processSteps: [
          {
            headline: tShopify("processSteps.consulting.title"),
            text: tShopify("processSteps.consulting.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tShopify("processSteps.conceptionAndDesign.title"),
            text: tShopify("processSteps.conceptionAndDesign.text"),
            icon: <DesignServicesIcon />,
          },
          {
            headline: tShopify("processSteps.coding.title"),
            text: tShopify("processSteps.coding.text"),
            icon: <TerminalIcon />,
          },
        ],
        toolLogos: [toolMap[Tool.Shopify], toolMap[Tool.ShopifyHydrogen]],
      },
      [SubServiceDetailPage.Frontend]: {
        ...frontend,
        processSteps: [
          {
            headline: tFrontend("processSteps.consulting.title"),
            text: tFrontend("processSteps.consulting.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tFrontend("processSteps.conceptionAndDesign.title"),
            text: tFrontend("processSteps.conceptionAndDesign.text"),
            icon: <DesignServicesIcon />,
          },
          {
            headline: tFrontend("processSteps.coding.title"),
            text: tFrontend("processSteps.coding.text"),
            icon: <TerminalIcon />,
          },
        ],
        toolLogos: [
          toolMap[Tool.React],
          toolMap[Tool.NextJs],
          toolMap[Tool.Gatsby],
          toolMap[Tool.TypeScript],
          toolMap[Tool.JamStack],
        ],
      },
      [SubServiceDetailPage.Shop]: {
        ...shop,
        processSteps: [
          {
            headline: tShop("processSteps.consulting.title"),
            text: tShop("processSteps.consulting.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tShop("processSteps.conceptionAndDesign.title"),
            text: tShop("processSteps.conceptionAndDesign.text"),
            icon: <DesignServicesIcon />,
          },
          {
            headline: tShop("processSteps.coding.title"),
            text: tShop("processSteps.coding.text"),
            icon: <TerminalIcon />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Shopify],
          toolMap[Tool.Woocommerce],
          toolMap[Tool.ShopifyHydrogen],
        ],
      },
      [SubServiceDetailPage.Deployment]: {
        ...deployment,
        processSteps: [
          {
            headline: tDeployment("processSteps.analysis.title"),
            text: tDeployment("processSteps.analysis.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tDeployment("processSteps.implementation.title"),
            text: tDeployment("processSteps.implementation.text"),
            icon: <RocketLaunch />,
          },
          {
            headline: tDeployment("processSteps.qualityAssurance.title"),
            text: tDeployment("processSteps.qualityAssurance.text"),
            icon: <Star />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Gitlab],
          toolMap[Tool.Ansible],
          toolMap[Tool.Docker],
          toolMap[Tool.Vercel],
          toolMap[Tool.Kubernetes],
        ],
      },
      [SubServiceDetailPage.Docker]: {
        ...docker,
        processSteps: [
          {
            headline: tDocker("processSteps.analysis.title"),
            text: tDocker("processSteps.analysis.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tDocker("processSteps.implementation.title"),
            text: tDocker("processSteps.implementation.text"),
            icon: <RocketLaunch />,
          },
          {
            headline: tDocker("processSteps.qualityAssurance.title"),
            text: tDocker("processSteps.qualityAssurance.text"),
            icon: <Star />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Docker],
          toolMap[Tool.Gitlab],
          toolMap[Tool.Ansible],
          toolMap[Tool.Kubernetes],
        ],
      },
      [SubServiceDetailPage.Backend]: {
        ...backend,
        processSteps: [
          {
            headline: tBackend("processSteps.analysis.title"),
            text: tBackend("processSteps.analysis.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tBackend("processSteps.implementation.title"),
            text: tBackend("processSteps.implementation.text"),
            icon: <RocketLaunch />,
          },
          {
            headline: tBackend("processSteps.qualityAssurance.title"),
            text: tBackend("processSteps.qualityAssurance.text"),
            icon: <Star />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Django],
          toolMap[Tool.NodeJs],
          toolMap[Tool.Python],
          toolMap[Tool.TypeScript],
        ],
      },
      [SubServiceDetailPage.BackendPython]: {
        ...backendPython,
        processSteps: [
          {
            headline: tBackend("processSteps.analysis.title"),
            text: tBackend("processSteps.analysis.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tBackend("processSteps.implementation.title"),
            text: tBackend("processSteps.implementation.text"),
            icon: <RocketLaunch />,
          },
          {
            headline: tBackend("processSteps.qualityAssurance.title"),
            text: tBackend("processSteps.qualityAssurance.text"),
            icon: <Star />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Django],
          toolMap[Tool.Python],
          toolMap[Tool.Spacy],
        ],
      },
      [SubServiceDetailPage.BackendNodeJs]: {
        ...backendNodeJs,
        processSteps: [
          {
            headline: tBackend("processSteps.analysis.title"),
            text: tBackend("processSteps.analysis.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tBackend("processSteps.implementation.title"),
            text: tBackend("processSteps.implementation.text"),
            icon: <RocketLaunch />,
          },
          {
            headline: tBackend("processSteps.qualityAssurance.title"),
            text: tBackend("processSteps.qualityAssurance.text"),
            icon: <Star />,
          },
        ],
        toolLogos: [
          toolMap[Tool.NodeJs],
          toolMap[Tool.TypeScript],
          toolMap[Tool.NestJs],
          toolMap[Tool.ExpressJs],
          toolMap[Tool.Strapi],
        ],
      },
      [SubServiceDetailPage.CorporateWebsite]: {
        ...corporateWebsites,
        processSteps: [
          {
            headline: tCorporateWebsites("processSteps.consulting.title"),
            text: tCorporateWebsites("processSteps.consulting.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tCorporateWebsites(
              "processSteps.conceptionAndDesign.title"
            ),
            text: tCorporateWebsites("processSteps.conceptionAndDesign.text"),
            icon: <DesignServicesIcon />,
          },
          {
            headline: tCorporateWebsites("processSteps.coding.title"),
            text: tCorporateWebsites("processSteps.coding.text"),
            icon: <TerminalIcon />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Raidbox],
          toolMap[Tool.NextJs],
          toolMap[Tool.Gatsby],
          toolMap[Tool.WordPress],
        ],
      },
      [SubServiceDetailPage.AgencyMainz]: {
        ...corporateWebsitesMainz,
        processSteps: [
          {
            headline: tCorporateWebsitesMainz("processSteps.consulting.title"),
            text: tCorporateWebsitesMainz("processSteps.consulting.text"),
            icon: <QuestionAnswerIcon />,
          },
          {
            headline: tCorporateWebsitesMainz(
              "processSteps.conceptionAndDesign.title"
            ),
            text: tCorporateWebsitesMainz(
              "processSteps.conceptionAndDesign.text"
            ),
            icon: <DesignServicesIcon />,
          },
          {
            headline: tCorporateWebsitesMainz("processSteps.coding.title"),
            text: tCorporateWebsitesMainz("processSteps.coding.text"),
            icon: <TerminalIcon />,
          },
        ],
        toolLogos: [
          toolMap[Tool.Raidbox],
          toolMap[Tool.NextJs],
          toolMap[Tool.Gatsby],
          toolMap[Tool.WordPress],
        ],
      },
    }),
    [
      shopify,
      tShopify,
      toolMap,
      frontend,
      tFrontend,
      shop,
      tShop,
      deployment,
      tDeployment,
      docker,
      tDocker,
      backend,
      tBackend,
      backendPython,
      backendNodeJs,
      corporateWebsites,
      tCorporateWebsites,
      corporateWebsitesMainz,
      tCorporateWebsitesMainz,
    ]
  );
};
