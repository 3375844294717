import { makeStyles } from "tss-react/mui";

export const useNWONFloatingContactBubblesStyles = makeStyles()((theme) => ({
  bubbleWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    "& *": {
      overflow: "visible!important",
    },
  },
  bubbleNWON: {
    zIndex: 1,
    left: "30%",
    top: "-40%",
    [theme.breakpoints.down("lg")]: {
      left: "69%",
      top: "-24%",
    },
    [theme.breakpoints.down("md")]: {
      left: "25%",
      top: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "36%",
      top: "89%",
    },
  },
  bubbleStefan: {
    zIndex: 1,
    left: "2%",
    top: 0,
    [theme.breakpoints.down("lg")]: {
      left: "64%",
      top: "63%",
    },
    [theme.breakpoints.down("md")]: {
      left: 0,
      top: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      left: 0,
      top: "113%",
    },
  },
  bubbleReik: {
    zIndex: 1,
    left: "90%",
    top: "-45%",
    [theme.breakpoints.down("lg")]: {
      left: "87%",
      top: "-45%",
    },
    [theme.breakpoints.down("md")]: {
      left: "57%",
      top: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "50%",
      top: "45%",
    },
  },
  bubbleFelix: {
    zIndex: 1,
    left: "40%",
    top: "28%",
    [theme.breakpoints.down("lg")]: {
      left: "80%",
      top: "28%",
    },
    [theme.breakpoints.down("md")]: {
      left: "82%",
      top: "65%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "80%",
      top: "98%",
    },
  },
}));
