import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import { Box, Grid, NoSsr, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import {
  NWONTrackingEvent,
  trackEvent,
} from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import { NWONCopyToClipboardButton } from "../../02-molecules/NWONCopyToClipboardButton/NWONCopyToClipboardButton";
import NWONFloatingBubbles from "../../02-molecules/NWONFloatingBubbles/NWONFloatingBubbles";
import {
  BubblePageSection,
  ParallaxEffectDirection,
} from "../../02-molecules/NWONFloatingBubbles/NWONFloatingBubblesInterfaces";
import NWONSection from "../NWONSection/NWONSection";
import { NWONContactSectionProps } from "./NWONContactSectionInterfaces";
import { useNWONContactSectionStyles } from "./NWONContactSectionStyles";
import NWONCTAIconList from "./NWONCTAIconList/NWONCTAIconList";

const NWONContactSection = (props: NWONContactSectionProps): JSX.Element => {
  const { classes } = useNWONContactSectionStyles();
  const { t } = useTranslation<"common">();

  const handleAdConversion = () => {
    trackEvent(NWONTrackingEvent.AdConversion);
  };

  const email = t("contactInformation.email");
  const phone = t("contactInformation.phone");
  const copyText = t("footer.copyToClipboard");
  const copiedText = t("footer.copiedToClipboard");

  return (
    <NWONSection
      className={classes.wrapperSection}
      id={t("menuItems.contact").toLowerCase()}
    >
      <Grid container={true} className={classes.innerWrapperSection}>
        <Grid item={true} xs={12} lg={7}>
          <Typography variant="h2">{t("footer.contactHeading")}</Typography>

          <Box className={classes.benefitsListWrapper}>
            <NWONCTAIconList />
          </Box>

          <Grid container={true} className={classes.buttonsWrapper}>
            <NWONCopyToClipboardButton
              buttonGroupColor="secondary"
              leftButtonProps={{
                startIcon: <EmailOutlinedIcon />,
                component: "a",
                href: `mailto:${email}`,
                children: email,
              }}
              buttonGroupVariant="outlined"
              textToCopy={email}
              copyTooltipText={copyText}
              copiedTooltipText={copiedText}
              buttonOnClick={handleAdConversion}
              copyOnClick={handleAdConversion}
            />

            <NWONCopyToClipboardButton
              buttonGroupColor="secondary"
              buttonGroupVariant="outlined"
              textToCopy={phone}
              copyTooltipText={copyText}
              leftButtonProps={{
                startIcon: <PhoneEnabledOutlinedIcon />,
                component: "a",
                href: `tel:${phone}`,
                children: phone,
              }}
              copiedTooltipText={copiedText}
              buttonOnClick={handleAdConversion}
              copyOnClick={handleAdConversion}
            />
          </Grid>
        </Grid>

        <Grid item={true} lg={5} className={classes.floatingBubblesWrapper}>
          <NoSsr>
            <NWONFloatingBubbles
              direction={ParallaxEffectDirection.BottomToTop}
              offsetTopOfPageRefCurrent={props.offsetTopOfPageRefCurrent}
              measuresSurroundingBox={props.measures}
              pageSection={BubblePageSection.ContactSection}
            />
          </NoSsr>
        </Grid>
      </Grid>
    </NWONSection>
  );
};

export default NWONContactSection;
