import { makeStyles } from "tss-react/mui";

export const useNWONLanguageSwitcherStyles = makeStyles()(() => ({
  languageImageNonActive: {
    opacity: "0.3",
  },
  languageButton: {
    width: 32,
    height: 32,
    "& img": {
      width: 32,
      height: 32,
    },
  },
}));
