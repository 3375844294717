import DOMPurify from "isomorphic-dompurify";

const defaultOptions: DOMPurify.Config = {
  ALLOWED_ATTR: [
    "target",
    "href",
    "class",
    "style",
    "src",
    "alt",
    "className",
    "draggable",
    "class",
    "parentNode",
  ],
  ALLOWED_TAGS: ["p", "a", "br", "span", "strong", "img", "s"],
};

export const sanitizeHtml = (
  html: string,
  sanitizeOptions?: DOMPurify.Config
): string => {
  return DOMPurify.sanitize(html, {
    ...defaultOptions,
    ...sanitizeOptions,
  }).toString();
};
