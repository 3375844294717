import Head from "next/head";

import { useMetaDescription } from "../../hooks/useMetaDescription";
import { useMetaTitle } from "../../hooks/useMetaTitle";

export const NWONMetaTags = (): JSX.Element => {
  const metaTitle = useMetaTitle();
  const metaDescription = useMetaDescription();

  return (
    <Head>
      <title>{metaTitle}</title>

      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />

      <meta property="og:description" content={metaDescription} />

      <meta name="viewport" content="initial-scale=1, width=device-width" />

      <meta property="og:type" content="website" />

      <meta property="og:image" content="/nwon-logo.gif" />

      <meta property="og:image:type" content="image/gif" />

      <meta property="og:image:secure_url" content="/nwon-logo.gif" />

      <meta property="og:image:width" content="1200" />

      <meta property="og:image:height" content="1200" />

      <meta property="og:video" content="/nwon-logo.mp4" />

      <meta property="og:video:secure_url" content="/nwon-logo.mp4" />

      <meta property="og:video:type" content="video/mp4" />

      <meta property="og:video:width" content="1200" />

      <meta property="og:video:height" content="1200" />

      <meta property="og:url" content="/nwon-logo.gif" />

      <link rel="shortcut icon" href="/favicon-32x32.png" />
    </Head>
  );
};
